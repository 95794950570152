<template>
    <div id="innovation">
        <div class="intro-text">{{ __('project.innovation_erklaerung') }}</div>
        <div class="block">
            <div class="form-group mb-0">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="blankCheckbox0" v-model="formData.innovation_1" @change="changeRating()">
                    <label class="form-check-label" for="blankCheckbox0">
                        {{ __('project.innovation1') }}
                        <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innovation_tooltip1')+tooltipDivEnd">
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                </div>
            </div>
            <div class="form-group mb-0">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="blankCheckbox1" v-model="formData.innovation_2" @change="changeRating()">
                    <label class="form-check-label" for="blankCheckbox1">
                        {{ __('project.innovation2') }}
                        <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innovation_tooltip2')+tooltipDivEnd">
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                </div>
            </div>
            <div class="form-group mb-0">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="blankCheckbox2" v-model="formData.innovation_3" @change="changeRating()">
                    <label class="form-check-label" for="blankCheckbox2">
                        {{ __('project.innovation3') }}
                        <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innovation_tooltip3')+tooltipDivEnd">
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                </div>
            </div>
            <div class="form-group mb-0">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="blankCheckbox3" v-model="formData.innovation_4" @change="changeRating()">
                    <label class="form-check-label" for="blankCheckbox3">
                        {{ __('project.innovation4') }}
                        <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innovation_tooltip4')+tooltipDivEnd">
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                </div>
            </div>
            <div class="form-group mb-0">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="blankCheckbox4" v-model="formData.innovation_5" @change="changeRating()">
                    <label class="form-check-label" for="blankCheckbox4">
                        {{ __('project.innovation5') }}
                        <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innovation_tooltip5')+tooltipDivEnd">
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                </div>
            </div>
            <div class="form-group mb-0">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="blankCheckbox5" v-model="formData.innovation_6" @change="changeRating()">
                    <label class="form-check-label" for="blankCheckbox5">
                        {{ __('project.innovation6') }}
                        <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innovation_tooltip6')+tooltipDivEnd">
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                </div>
            </div>
            <div class="form-group mb-0">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="blankCheckbox6" v-model="formData.innovation_7" @change="changeRating()">
                    <label class="form-check-label" for="blankCheckbox6">
                        {{ __('project.innovation7') }}
                        <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innovation_tooltip7')+tooltipDivEnd">
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                </div>
            </div>
            <div class="form-group mb-0">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="blankCheckbox7" v-model="formData.innovation_8" @change="changeRating()">
                    <label class="form-check-label" for="blankCheckbox7">
                        {{ __('project.innovation8') }}
                        <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innovation_tooltip8')+tooltipDivEnd">
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                </div>
            </div>
            <div class="form-group mb-0">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="blankCheckbox8" v-model="formData.innovation_9" @change="changeRating()">
                    <label class="form-check-label" for="blankCheckbox8">
                        {{ __('project.innovation9') }}
                        <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innovation_tooltip9')+tooltipDivEnd">
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                </div>
            </div>
            <div class="form-group mb-0">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="blankCheckbox9" v-model="formData.innovation_10" @change="changeRating()">
                    <label class="form-check-label" for="blankCheckbox9">
                        {{ __('project.innovation10') }}
                        <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innovation_tooltip10')+tooltipDivEnd">
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                </div>
            </div>
        </div>

        <div class="points-box mb-2">
            <p class="title mb-2">{{ __('project.innovation_ergebnis') }}</p>
            <p class="points mb-0">{{ formData.innovation_rating }} {{ __('project.von') }} 10</p>
        </div>

        <p class="mt-5" style="font-size: 1rem; opacity: 0.75;">{{ __('project.innovation_nachweise') }}</p>

    </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        project_data: {
            type: String
        }
    },
    data() {
        return {
            formData: {
                innovation_1: false,
                innovation_2: false,
                innovation_3: false,
                innovation_4: false,
                innovation_5: false,
                innovation_6: false,
                innovation_7: false,
                innovation_8: false,
                innovation_9: false,
                innovation_10: false,
                innovation_rating: 0,
            },
            tooltipDiv: "<div class='tooltip_value'>",
            tooltipDivEnd: "</div>"
        }
    },
    mounted() {
        var projectData = JSON.parse(this.project_data);

        Object.keys(this.formData).forEach(item => {
            if (item == "innovation_rating") this.formData.innovation_rating = projectData.innovation_rating ?? 0;
            else this.formData[item] = projectData[item] == 1 ? true : false;
        });

        document.addEventListener('DOMContentLoaded', (event) => {
            store.nextButtonDisabled = false;
        });

        this.changeRating();
    },
    methods: {
        changeRating: function() {
            this.formData.innovation_rating = 0;
            Object.keys(this.formData).forEach(item => {
                if (item == "innovation_rating") return;
                if (this.formData[item] === true) this.formData.innovation_rating += 1;
            });
            store.innovation_rating = this.formData.innovation_rating;
            store.formData = this.formData;
        }
    }
}
</script>
