<template>
    <div id="info">
        <div style="margin-top:80px; line-height:1.6;">
            <div class="mt-4 mb-3 info-title" v-html="__('project.hotline')">
            </div>
            <p>
                {{ __('project.kontaktinfo_adresse') }}<br>
                <a :href="__('project.kontaktinfo_tel2')">{{ __('project.kontaktinfo_tel') }}</a><br>
                <a :href="__('project.kontaktinfo_mail2')">{{ __('project.kontaktinfo_mail') }}</a><br>
            </p>
        </div>
    </div>
</template>
