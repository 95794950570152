require('./bootstrap');

// Components

// Global Variables
window.siteUrl = document.querySelector("meta[name='site-url']").getAttribute("content");
window.token = document.querySelector("meta[name='csrf-token']").getAttribute("content");
window.player = null;

import { createApp } from 'vue'
import { store } from './store.js'

// Import Components

const app = createApp({
    data() {
        // return {
        //     store,
        // }
        return {} // Should return object
    },
    computed: {
        // a computed getter
        project_data: function () {
            if (props_data) {
                return JSON.parse(atob(props_data))
            }
            return false;
        },
    },
    mounted() {
        store.betriebsenergie_rating = this.project_data.betriebsenergie_rating ?? 0;
        store.bauoekologie_rating = this.project_data.bauoekologie_rating ?? 0;
        store.standort_rating = this.project_data.standort_rating ?? 0;
        store.zersiedlung_rating = this.project_data.zersiedlung_rating ?? 0;
        store.zersiedlung_rating_definitiv = this.project_data.zersiedlung_rating_definitiv ?? 0;
        store.nutzung_rating = this.project_data.nutzung_rating ?? 0;
        store.wirtschaftlichkeit_rating = this.project_data.wirtschaftlichkeit_rating ?? 0;
        store.innovation_rating = this.project_data.innovation_rating ?? 0;
        store.completed = this.project_data.completed;
    },
    methods: {
        // googleMaps.js ruft dies auf bei Polygon Drag auf Map
        grundrissMove: function(polygon)
        {
            console.log('app.js map grundriss move');
            // this.$root.$refs.betriebsenergie.$refs.GoogleMap.cutBuilding(polygon); // Call Function cutBuilding in googleMaps.vue Component
            this.$root.$refs.betriebsenergie.cutBuilding(polygon); // Call Function cutBuilding in Betriebsenergie.vue Component
        }
    }
});

// Register Component
app.component("tabs-navigation", require('./components/TabsNavigation.vue').default);
app.component("rating", require('./components/Rating.vue').default);
app.component("nachhaltigkeit", require('./components/Nachhaltigkeit.vue').default);
app.component("radar-chart", require('./components/RadarChart.vue').default);
app.component("info", require('./components/Info.vue').default);
app.component("next-button", require('./components/NextButton.vue').default);

app.component("betriebsenergie", require('./components/Betriebsenergie.vue').default);
    app.component('google-map', require('./components/googleMaps.vue').default);
app.component("bauoekologie", require('./components/Bauoekologie.vue').default);
app.component("standort", require('./components/Standort.vue').default);
app.component("zersiedlung", require('./components/Zersiedlung.vue').default);
app.component("nutzung", require('./components/Nutzung.vue').default);
app.component("wirtschaftlichkeit", require('./components/Wirtschaftlichkeit.vue').default);
app.component("innovation", require('./components/Innovation.vue').default);
app.component("zertifikat", require('./components/Zertifikat.vue').default);
app.component("kontakt", require('./components/Kontaktdaten.vue').default);
app.component("projects", require('./components/Projektverwaltung.vue').default);
app.component("unfinishedprojects", require('./components/UnfinishedProjekte.vue').default);
app.component("users", require('./components/Benutzerverwaltung.vue').default);

// Mixin Translations
app.mixin(require('./trans'));

// Mount Vue App
window.appRoot = app.mount("#app");

$(function () {
    // $('[data-toggle="tooltip"]').tooltip({
    //     html:true,
    //     // container: '#app'
    // });


    setTimeout(() => {
        console.log('init tooltip')
        $('[data-toggle="tooltip"]').tooltip({html:true});
     }, 1000);

});
