<template>
    <div id="wirtschaftlichkeit">
        <div class="intro-text">{{ __('project.wirtschaftlichkeit_erklaerung') }}</div>

        <div class="block">

            <h3>{{ __('project.gebaeudewert') }}</h3>

            <!-- Einschätzung Verhältnis -->
            <div class="form-group" style="margin-top: 0px;">
                <label class="input-label">{{ __('project.einschaetzung_verhaeltnis') }}</label>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline1" name="customRadioInline1"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_verhaeltnis"
                            value="1"
                            @change="changeRating()"
                            data_points="0.7"
                    >
                    <label class="custom-control-label" for="customRadioInline1">
                        {{ __('project.bis_1_3') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline2" name="customRadioInline2"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_verhaeltnis"
                            value="2"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline2">
                        {{ __('project.bis_2_3') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline3" name="customRadioInline3"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_verhaeltnis"
                            value="3"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline3">
                        {{ __('project.ueber_2_3') }}
                    </label>
                </div>
            </div>

            <h3>{{ __('project.durchschnittsalter') }}</h3>
            <p style="font-size: 1.1rem;">{{ __('project.einschaetzung_avg_age') }}</p>

            <!-- Durchschnittsalter Tragwerk -->
            <div class="form-group">
                <label class="input-label">{{ __('project.tragwerk') }}</label>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline4" name="customRadioInline4"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_tragwerk"
                            value="1"
                            @change="changeRating()"
                            data_points="0.7"
                    >
                    <label class="custom-control-label" for="customRadioInline4">
                        {{ __('project.avg_age_15') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline5" name="customRadioInline5"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_tragwerk"
                            value="2"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline5">
                        {{ __('project.avg_age_35') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline6" name="customRadioInline6"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_tragwerk"
                            value="3"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline6">
                        {{ __('project.avg_age_50') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline7" name="customRadioInline7"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_tragwerk"
                            value="4"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline7">
                        {{ __('project.avg_age_ueber_50') }}
                    </label>
                </div>
            </div>

            <!-- Durchschnittsalter Fassade -->
            <div class="form-group">
                <label class="input-label">{{ __('project.fassade') }}</label>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline8" name="customRadioInline8"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_fassade"
                            value="1"
                            @change="changeRating()"
                            data_points="0.7"
                    >
                    <label class="custom-control-label" for="customRadioInline8">
                        {{ __('project.avg_age_15') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline9" name="customRadioInline9"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_fassade"
                            value="2"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline9">
                        {{ __('project.avg_age_35') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline10" name="customRadioInline10"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_fassade"
                            value="3"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline10">
                        {{ __('project.avg_age_50') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline11" name="customRadioInline11"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_fassade"
                            value="4"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline11">
                        {{ __('project.avg_age_ueber_50') }}
                    </label>
                </div>
            </div>

            <!-- Durchschnittsalter Fenster -->
            <div class="form-group">
                <label class="input-label">{{ __('project.fenster') }}</label>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline12" name="customRadioInline12"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_fenster"
                            value="1"
                            @change="changeRating()"
                            data_points="0.7"
                    >
                    <label class="custom-control-label" for="customRadioInline12">
                        {{ __('project.avg_age_15') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline13" name="customRadioInline13"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_fenster"
                            value="2"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline13">
                        {{ __('project.avg_age_35') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline14" name="customRadioInline14"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_fenster"
                            value="3"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline14">
                        {{ __('project.avg_age_50') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline15" name="customRadioInline15"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_fenster"
                            value="4"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline15">
                        {{ __('project.avg_age_ueber_50') }}
                    </label>
                </div>
            </div>

            <!-- Durchschnittsalter Küche -->
            <div class="form-group">
                <label class="input-label">{{ __('project.kueche') }}</label>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline16" name="customRadioInline16"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_kueche"
                            value="1"
                            @change="changeRating()"
                            data_points="0.7"
                    >
                    <label class="custom-control-label" for="customRadioInline16">
                        {{ __('project.avg_age_15') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline17" name="customRadioInline17"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_kueche"
                            value="2"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline17">
                        {{ __('project.avg_age_35') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline18" name="customRadioInline18"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_kueche"
                            value="3"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline18">
                        {{ __('project.avg_age_50') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline19" name="customRadioInline19"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_kueche"
                            value="4"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline19">
                        {{ __('project.avg_age_ueber_50') }}
                    </label>
                </div>
            </div>

            <!-- Durchschnittsalter Nasszellen und Sanitär -->
            <div class="form-group">
                <label class="input-label">{{ __('project.nasszellen') }}</label>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline20" name="customRadioInline20"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_nasszellen"
                            value="1"
                            @change="changeRating()"
                            data_points="0.7"
                    >
                    <label class="custom-control-label" for="customRadioInline20">
                        {{ __('project.avg_age_15') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline21" name="customRadioInline21"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_nasszellen"
                            value="2"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline21">
                        {{ __('project.avg_age_35') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline22" name="customRadioInline22"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_nasszellen"
                            value="3"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline22">
                        {{ __('project.avg_age_50') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline23" name="customRadioInline23"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_nasszellen"
                            value="4"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline23">
                        {{ __('project.avg_age_ueber_50') }}
                    </label>
                </div>
            </div>

            <!-- Durchschnittsalter Heizung -->
            <div class="form-group">
                <label class="input-label">{{ __('project.heizung') }}</label>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline24" name="customRadioInline24"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_heizung"
                            value="1"
                            @change="changeRating()"
                            data_points="0.7"
                    >
                    <label class="custom-control-label" for="customRadioInline24">
                        {{ __('project.avg_age_15') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline25" name="customRadioInline25"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_heizung"
                            value="2"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline25">
                        {{ __('project.avg_age_35') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline26" name="customRadioInline26"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_heizung"
                            value="3"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline26">
                        {{ __('project.avg_age_50') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline27" name="customRadioInline27"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_heizung"
                            value="4"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline27">
                        {{ __('project.avg_age_ueber_50') }}
                    </label>
                </div>
            </div>

            <!-- Durchschnittsalter Elektro und Lüftung -->
            <div class="form-group">
                <label class="input-label">{{ __('project.elektro') }}</label>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline28" name="customRadioInline28"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_elektro"
                            value="1"
                            @change="changeRating()"
                            data_points="0.7"
                    >
                    <label class="custom-control-label" for="customRadioInline28">
                        {{ __('project.avg_age_15') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline29" name="customRadioInline29"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_elektro"
                            value="2"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline29">
                        {{ __('project.avg_age_35') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline30" name="customRadioInline30"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_elektro"
                            value="3"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline30">
                        {{ __('project.avg_age_50') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline31" name="customRadioInline31"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_elektro"
                            value="4"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline31">
                        {{ __('project.avg_age_ueber_50') }}
                    </label>
                </div>
            </div>

            <!-- Durchschnittsalter Lift -->
            <div class="form-group">
                <label class="input-label">{{ __('project.lift') }}</label>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline32a" name="customRadioInline32a"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_lift"
                            value="0"
                            @change="changeRating()"
                            data_points="0"
                    >
                    <label class="custom-control-label" for="customRadioInline32a">
                        {{ __('project.ohne_lift') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline32" name="customRadioInline32"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_lift"
                            value="1"
                            @change="changeRating()"
                            data_points="0.7"
                    >
                    <label class="custom-control-label" for="customRadioInline32">
                        {{ __('project.avg_age_15') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline33" name="customRadioInline33"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_lift"
                            value="2"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline33">
                        {{ __('project.avg_age_35') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline34" name="customRadioInline34"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_lift"
                            value="3"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline34">
                        {{ __('project.avg_age_50') }}
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline35" name="customRadioInline35"
                            class="answer custom-control-input"
                            v-model="formData.wirtschaftlichkeit_avg_age_lift"
                            value="4"
                            @change="changeRating()"
                            data_points="1.25"
                    >
                    <label class="custom-control-label" for="customRadioInline35">
                        {{ __('project.avg_age_ueber_50') }}
                    </label>
                </div>
            </div>

        </div>

        <!-- Rating Box -->
        <div class="points-box mb-2">
            <p class="title mb-2">{{ __('project.wirtschaftlichkeit_ergebnis') }}</p>
            <p class="points mb-0">{{ formData.wirtschaftlichkeit_rating }} {{ __('project.von') }} 10</p>
        </div>
    </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        project_data: {
            type: String
        }
    },
    data() {
        return {
            formData: {
                wirtschaftlichkeit_heizung: false,
                wirtschaftlichkeit_heizungstyp: "",
                wirtschaftlichkeit_pv: false,
                wirtschaftlichkeit_dach: false,
                wirtschaftlichkeit_fassade: false,
                wirtschaftlichkeit_keller: false,
                wirtschaftlichkeit_fenster: false,
                wirtschaftlichkeit_regenwasser: false,

                wirtschaftlichkeit_rating: 0,

                wirtschaftlichkeit_verhaeltnis: false,
                wirtschaftlichkeit_avg_age_tragwerk: false,
                wirtschaftlichkeit_avg_age_fassade: false,
                wirtschaftlichkeit_avg_age_fenster: false,
                wirtschaftlichkeit_avg_age_kueche: false,
                wirtschaftlichkeit_avg_age_nasszellen: false,
                wirtschaftlichkeit_avg_age_heizung: false,
                wirtschaftlichkeit_avg_age_elektro: false,
                wirtschaftlichkeit_avg_age_lift: null,

                avg_age_gebaeudeteile: 0,
            },
            bauvorhaben: ''
        }
    },
    mounted() {
        var projectData = JSON.parse(this.project_data);
        console.log("projectData", projectData);

        /*
        this.bauvorhaben = projectData.bauvorhaben;

        console.log("projectData.heizsystemNeu", projectData.heizsystemNeu)

        // Set Heizung from Project HeizsystemNeu if not yet set
        if(projectData.bauvorhaben == 1) { // Sanierung
            if(this.wirtschaftlichkeit_heizungstyp != false && projectData.heizsystemNeu != false) {
                this.formData.wirtschaftlichkeit_heizungstyp = projectData.heizsystemNeu;
            } else {
                this.formData.wirtschaftlichkeit_heizungstyp = projectData.wirtschaftlichkeit_heizungstyp != null ? projectData.wirtschaftlichkeit_heizungstyp.toString() : "";
            }
        } else {
            if(this.wirtschaftlichkeit_heizungstyp != false && projectData.heizsystem != false) {
                this.formData.wirtschaftlichkeit_heizungstyp = projectData.heizsystem;
            } else {
                this.formData.wirtschaftlichkeit_heizungstyp = projectData.wirtschaftlichkeit_heizungstyp != null ? projectData.wirtschaftlichkeit_heizungstyp.toString() : "";
            }
        }

        this.formData.wirtschaftlichkeit_heizung = projectData.wirtschaftlichkeit_heizung == 1 ? true : false;
        this.formData.wirtschaftlichkeit_pv = projectData.wirtschaftlichkeit_pv == 1 ? true : false;
        this.formData.wirtschaftlichkeit_dach = projectData.wirtschaftlichkeit_dach == 1 ? true : false;
        this.formData.wirtschaftlichkeit_fassade = projectData.wirtschaftlichkeit_fassade == 1 ? true : false;
        this.formData.wirtschaftlichkeit_keller = projectData.wirtschaftlichkeit_keller == 1 ? true : false;
        this.formData.wirtschaftlichkeit_fenster = projectData.wirtschaftlichkeit_fenster == 1 ? true : false;
        this.formData.wirtschaftlichkeit_regenwasser = projectData.wirtschaftlichkeit_regenwasser == 1 ? true : false;
        this.formData.wirtschaftlichkeit_rating = projectData.wirtschaftlichkeit_rating ?? 0;
        */

        this.formData.wirtschaftlichkeit_verhaeltnis = projectData.wirtschaftlichkeit_verhaeltnis;
        this.formData.wirtschaftlichkeit_avg_age_tragwerk = projectData.wirtschaftlichkeit_avg_age_tragwerk;
        this.formData.wirtschaftlichkeit_avg_age_fassade = projectData.wirtschaftlichkeit_avg_age_fassade;
        this.formData.wirtschaftlichkeit_avg_age_fenster = projectData.wirtschaftlichkeit_avg_age_fenster;
        this.formData.wirtschaftlichkeit_avg_age_kueche = projectData.wirtschaftlichkeit_avg_age_kueche;
        this.formData.wirtschaftlichkeit_avg_age_nasszellen = projectData.wirtschaftlichkeit_avg_age_nasszellen;
        this.formData.wirtschaftlichkeit_avg_age_heizung = projectData.wirtschaftlichkeit_avg_age_heizung;
        this.formData.wirtschaftlichkeit_avg_age_elektro = projectData.wirtschaftlichkeit_avg_age_elektro;
        this.formData.wirtschaftlichkeit_avg_age_lift = projectData.wirtschaftlichkeit_avg_age_lift;

        if(this.formData.wirtschaftlichkeit_verhaeltnis === false) { this.formData.wirtschaftlichkeit_verhaeltnis = 0; }
        if(this.formData.wirtschaftlichkeit_avg_age_tragwerk === false) { this.formData.wirtschaftlichkeit_avg_age_tragwerk = 0; }
        if(this.formData.wirtschaftlichkeit_avg_age_fassade === false) { this.formData.wirtschaftlichkeit_avg_age_fassade = 0; }
        if(this.formData.wirtschaftlichkeit_avg_age_fenster === false) { this.formData.wirtschaftlichkeit_avg_age_fenster = 0; }
        if(this.formData.wirtschaftlichkeit_avg_age_kueche === false) { this.formData.wirtschaftlichkeit_avg_age_kueche = 0; }
        if(this.formData.wirtschaftlichkeit_avg_age_nasszellen === false) { this.formData.wirtschaftlichkeit_avg_age_nasszellen = 0; }
        if(this.formData.wirtschaftlichkeit_avg_age_heizung === false) { this.formData.wirtschaftlichkeit_avg_age_heizung = 0; }
        if(this.formData.wirtschaftlichkeit_avg_age_elektro === false) { this.formData.wirtschaftlichkeit_avg_age_elektro = 0; }
        if(this.formData.wirtschaftlichkeit_avg_age_lift === false) { this.formData.wirtschaftlichkeit_avg_age_lift = null; }


        document.addEventListener('DOMContentLoaded', (event) => {
           this.changeRating();
        });

    },
    methods: {
        changeRating: function() {
            console.log("changeRating");

            this.formData.wirtschaftlichkeit_rating = 0;

            /*
            Object.keys(this.formData).forEach(item => {

                if (item == "wirtschaftlichkeit_rating" || item == "wirtschaftlichkeit_heizungstyp") return;

                // Punkte Select Heizungstyp wenn checkbox Heizung ausgewählt ist
                if (item == "wirtschaftlichkeit_heizung") {

                    this.formData.wirtschaftlichkeit_heizungstyp = parseInt(this.formData.wirtschaftlichkeit_heizungstyp);

                    if (this.formData[item] === true) {
                        switch(this.formData.wirtschaftlichkeit_heizungstyp) {
                            case 1: // Luftwärmepumpe
                                this.formData.wirtschaftlichkeit_rating += 3.5;
                                break;
                            case 2: // Wärmepumpe mit Erdsonde
                                this.formData.wirtschaftlichkeit_rating += 4;
                                break;
                                case 3: // Holzpellets
                                this.formData.wirtschaftlichkeit_rating += 3.5;
                                break;
                            case 6: // Stückholz
                                this.formData.wirtschaftlichkeit_rating += 3.5;
                                break;
                            case 7: // Elektrospeicher
                            this.formData.wirtschaftlichkeit_rating += 0;
                            break;
                            case 8: // Fernwärme
                            this.formData.wirtschaftlichkeit_rating += 4;
                            break;
                            default:
                                this.formData.wirtschaftlichkeit_rating += 0;
                            }
                    }

                }
                else {
                    if (this.formData[item] === true) this.formData.wirtschaftlichkeit_rating += 1;
                }
            });
            */

            this.formData.wirtschaftlichkeit_avg_age_tragwerk = parseInt(this.formData.wirtschaftlichkeit_avg_age_tragwerk);
            this.formData.wirtschaftlichkeit_avg_age_fassade = parseInt(this.formData.wirtschaftlichkeit_avg_age_fassade);
            this.formData.wirtschaftlichkeit_avg_age_fenster = parseInt(this.formData.wirtschaftlichkeit_avg_age_fenster);
            this.formData.wirtschaftlichkeit_avg_age_kueche = parseInt(this.formData.wirtschaftlichkeit_avg_age_kueche);
            this.formData.wirtschaftlichkeit_avg_age_nasszellen = parseInt(this.formData.wirtschaftlichkeit_avg_age_nasszellen);
            this.formData.wirtschaftlichkeit_avg_age_heizung = parseInt(this.formData.wirtschaftlichkeit_avg_age_heizung);
            this.formData.wirtschaftlichkeit_avg_age_elektro = parseInt(this.formData.wirtschaftlichkeit_avg_age_elektro);
            this.formData.wirtschaftlichkeit_avg_age_lift = parseInt(this.formData.wirtschaftlichkeit_avg_age_lift);

            // console.log("wirtschaftlichkeit_avg_age_tragwerk", this.formData.wirtschaftlichkeit_avg_age_tragwerk);
            // console.log("wirtschaftlichkeit_avg_age_fassade", this.formData.wirtschaftlichkeit_avg_age_fassade);
            // console.log("wirtschaftlichkeit_avg_age_fenster", this.formData.wirtschaftlichkeit_avg_age_fenster);
            // console.log("wirtschaftlichkeit_avg_age_kueche", this.formData.wirtschaftlichkeit_avg_age_kueche);
            // console.log("wirtschaftlichkeit_avg_age_nasszellen", this.formData.wirtschaftlichkeit_avg_age_nasszellen);
            // console.log("wirtschaftlichkeit_avg_age_heizung", this.formData.wirtschaftlichkeit_avg_age_heizung);
            // console.log("wirtschaftlichkeit_avg_age_elektro", this.formData.wirtschaftlichkeit_avg_age_elektro);
            // console.log("wirtschaftlichkeit_avg_age_lift", this.formData.wirtschaftlichkeit_avg_age_lift);

            let teilenDurch = 8;
            // Kein Lift vorhanden?
            if(this.formData.wirtschaftlichkeit_avg_age_lift == 0) {
                teilenDurch = 7;
            }

            // Durchschnitt der Gebäudeteile
            this.formData.avg_age_gebaeudeteile =
                (this.formData.wirtschaftlichkeit_avg_age_tragwerk +
                this.formData.wirtschaftlichkeit_avg_age_fassade +
                this.formData.wirtschaftlichkeit_avg_age_fenster +
                this.formData.wirtschaftlichkeit_avg_age_kueche +
                this.formData.wirtschaftlichkeit_avg_age_nasszellen +
                this.formData.wirtschaftlichkeit_avg_age_heizung +
                this.formData.wirtschaftlichkeit_avg_age_elektro +
                this.formData.wirtschaftlichkeit_avg_age_lift) / teilenDurch;

            let durchschnittsalter = this.formData.avg_age_gebaeudeteile;
            let rating = 0;

            console.log("this.avg_age_gebaeudeteile", this.formData.avg_age_gebaeudeteile);

            /*
                Regeln gemäss ABS Word 230717
                Durchschnittsalter Gebäudeteile bis 15 Jahre und Belehnung bis 1/3 Gebäudewert (10 Punkte)
                Durchschnittsalter Gebäudeteile bis 15 Jahre und Belehnung bis 2/3 Gebäudewert (6 Punkte)

                Durchschnittsalter Gebäudeteile von 16 bis 35 Jahre und Belehnung bis 1/3 Gebäudewert (6 Punkte)
                Durchschnittsalter Gebäudeteile von 16 bis 35 Jahre und Belehnung bis 2/3 Gebäudewert (4 Punkte)

                Durchschnittsalter Gebäudeteile von 36 bis 50 Jahre und Belehnung bis 1/3 Gebäudewert (4 Punkte)
                Durchschnittsalter Gebäudeteile von 36 bis 50 Jahre und Belehnung über 1/3 Gebäudewert (0 Punkte)

                Durchschnittsalter Gebäudeteile über 50 Jahre und Belehnung bis 1/3 Gebäudewert (2) Punkte)
                Durchschnittsalter Gebäudeteile über 50 Jahre und Belehnung über 1/3 Gebäudewert (0 Punkte)
            */


            // Regeln:
            // Durchschnittsalter Gebäudeteile bis 15 Jahre (Value 1) und Belehnung bis 1/3 Gebäudewert (Value 1) (10 Punkte)
            // Statt 15 Jahre genau (alle auf 1) habe ich angepasst auf zwischen 1 und 1.5, da sonst Werte zwischen 1 und 2 verloren gehen
            if(durchschnittsalter <= 1.5 && this.formData.wirtschaftlichkeit_verhaeltnis == 1) {
                rating = 10;
            }
            // Durchschnittsalter Gebäudeteile bis 15 Jahre (Value 1) und Belehnung bis 2/3 Gebäudewert (Value 2) (6 Punkte)
            else if(durchschnittsalter <= 1.5 && this.formData.wirtschaftlichkeit_verhaeltnis == 2) {
                rating = 6;
            }
            // Durchschnittsalter Gebäudeteile von 16 bis 35 Jahre (Value 2) und Belehnung bis 1/3 Gebäudewert (Value 1) (6 Punkte)
            else if((durchschnittsalter > 1.5 && durchschnittsalter <= 2.5) && this.formData.wirtschaftlichkeit_verhaeltnis == 1) {
                rating = 6;
            }
            // Durchschnittsalter Gebäudeteile von 16 bis 35 Jahre (Value 2) und Belehnung bis 2/3 Gebäudewert (Value 2) (4 Punkte)
            else if((durchschnittsalter > 1.5 && durchschnittsalter <= 2.5) && this.formData.wirtschaftlichkeit_verhaeltnis == 2) {
                rating = 4;
            }
            // Durchschnittsalter Gebäudeteile von 36 bis 50 Jahre (Value 3) und Belehnung bis 1/3 Gebäudewert (Value 1) (4 Punkte)
            else if((durchschnittsalter > 2.5 && durchschnittsalter <= 3.5) && this.formData.wirtschaftlichkeit_verhaeltnis == 1) {
                rating = 4;
            }
            // Durchschnittsalter Gebäudeteile von 36 bis 50 Jahre (Value 3) und Belehnung über 1/3 Gebäudewert (Value 3) (0 Punkte)
            else if((durchschnittsalter > 2.5 && durchschnittsalter <= 3.5) && this.formData.wirtschaftlichkeit_verhaeltnis == 1) {
                rating = 0;
            }
            // Durchschnittsalter Gebäudeteile über 50 Jahre (Value 4) und Belehnung bis 1/3 Gebäudewert (Value 1) (2) Punkte)
            else if((durchschnittsalter > 3.5) && this.formData.wirtschaftlichkeit_verhaeltnis == 1) {
                rating = 2;
            }
            // Durchschnittsalter Gebäudeteile über 50 Jahre (Value 4) und Belehnung über 1/3 Gebäudewert (Value 3) (0 Punkte)
            else if((durchschnittsalter > 3.5) && this.formData.wirtschaftlichkeit_verhaeltnis == 2) {
                rating = 0;
            } else {
                // Was ist mit allen anderen Fällen? Z.B. Alter 1 und Belehnung über 2/3
                rating = 0;
            }

            console.log("rating", rating);
            this.formData.wirtschaftlichkeit_rating = rating;

            // Save in Store
            store.wirtschaftlichkeit_rating = this.formData.wirtschaftlichkeit_rating;
            store.formData = this.formData;
            console.log("formData", this.formData);

            // Validate next Button
            this.validateNextButton();
        },
        validateNextButton: function() {

            // Wenn alle Fragen ausgefüllt wurden, Next Button aktivieren
            if(
                this.formData.wirtschaftlichkeit_verhaeltnis != false &&
                this.formData.wirtschaftlichkeit_avg_age_tragwerk != false &&
                this.formData.wirtschaftlichkeit_avg_age_fassade != false &&
                this.formData.wirtschaftlichkeit_avg_age_fenster != false &&
                this.formData.wirtschaftlichkeit_avg_age_kueche != false &&
                this.formData.wirtschaftlichkeit_avg_age_nasszellen != false &&
                this.formData.wirtschaftlichkeit_avg_age_heizung != false &&
                this.formData.wirtschaftlichkeit_avg_age_elektro != false &&
                this.formData.wirtschaftlichkeit_avg_age_lift !== null
            ) {
                store.nextButtonDisabled = false;
            } else {
                store.nextButtonDisabled = true;
            }

        }
    }
}
</script>
