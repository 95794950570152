const API_KEY = 'AIzaSyBUckJObO1LOyzscN6alm4i8sGIuZQuTZE';
const CALLBACK_NAME = 'initMap';

let map;
let marker;
let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;
let markersToRemove = [];
var parzellePolygon = false;

// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

export function init()
{
    console.log("googleMaps.js init()")

    if (initialized) return initPromise;

    initialized = true;

    window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = 'https://maps.googleapis.com/maps/api/js?key='+API_KEY+'&callback='+CALLBACK_NAME+'&libraries=geometry';
    console.log("google maps url", script.src)
    script.onerror = rejectInitPromise;
    document.querySelector('head').appendChild(script);

    return initPromise;
}

export function firstLoadMap(elem, lat, lon)
{

    console.log("firstLoadMap()")

    var startpunkt = {lat: lat, lng: lon};

    return new Promise((resolve, reject) => {

        console.log("promise");

        map = new google.maps.Map(elem, {
            zoom: 21,
            center: startpunkt,
            tilt: 0,
            gestureHandling: 'greedy',
            mapTypeId: google.maps.MapTypeId.SATELLITE,
            mapTypeControl: false,
            fullscreenControl: false,
            disableDefaultUI: true,
            panControl: false,
            zoomControl: false,
            scaleControl: false,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.BOTTOM_CENTER
            },
            panControl: false,
            panControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.DEFAULT ,
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            scaleControl: false,
            scaleControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            streetViewControl: true,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            }
        });

        setMarker(map, lat, lon);

        // Drag Listener
        google.maps.event.addListener(marker, 'dragend', function() {
            console.log('dragend');
            var new_lat = marker.getPosition().lat();
            var new_lon = marker.getPosition().lng();
            lat = new_lat;
            lon = new_lon;

            var latlng = new google.maps.LatLng(new_lat, new_lon);

            moveMarker(new_lat,new_lon);

            window.app.newBuilding(lat, lon, null, null);

            // marker.setPosition(latlng);
        });

        // Set Zoom
        map.setZoom(20);

        console.log("resolve")
        resolve();
    });

}

function setMarker(map, lat, lon)
{
    var location = {lat: lat, lng: lon};

    marker = new google.maps.Marker({
        position: location,
        map: map,
        animation: google.maps.Animation.DROP,
        draggable: false
    });

    markersToRemove.push(marker);
    return;
}

// Google Maps Marker verschieben bei Adresssuche
export function moveMarker(lat, lon)
{
    var newLocation = {lat: lat, lng: lon};

    // Marker auf Karte setzen
    marker.setPosition(newLocation);

    // Karte Zentrieren
    map.setCenter(newLocation);

}

function moveMarkerOnLoad(lat, lon, attempt)
{
    attempt = (typeof attempt !== 'undefined') ?  attempt : 1;

    if(attempt == 10) {
        location.reload();
    }

    if(google === false) {
        attempt++;
        setTimeout(function(){
            moveMarkerOnLoad(lat, lon);

        }, 3000);
        return false;
    }

    google.maps.event.addListenerOnce(map, 'tilesloaded', function(){
            moveMarker(lat, lon);
    });

}

/**
 * Draw polygon (grundriss) on google map
 * @param  array polygonArr
 * @return null
 */
export function drawPolygonGrundriss(polygonArr)
{
    console.log("grundriss##############", typeof grundriss);
    if (typeof grundriss != 'undefined') {
        grundriss.setMap(null);
    }

/*     console.log('polygonArr222', polygonArr) */

    if (polygonArr == undefined) {
        return false;
    }

    var points=[];
    for(var i=0; i<polygonArr.length; i++) {
        points.push(new google.maps.LatLng(polygonArr[i][0],polygonArr[i][1]));
    }

    // Construct the polygon.
    grundriss = new google.maps.Polygon({
        paths: points,
        strokeColor: '#ff0000',
        strokeOpacity: 0.8,
        strokeWeight: 4,
        fillColor: '#43ff3e',
        fillOpacity: 0
    });

    grundriss.setMap(map);
}

/**
 * Wird aufgerufen, wenn Polygon Punkte gedragt wurden
 */
export function polyChanged2()
{

    console.log("polyChanged2()");

    let parzellePath = parzellePolygon.getPath();
    let parzeleArr = new Array();
    parzeleArr[0] = new Array();

    console.log('parzellePath', parzellePath)

    for (var i = 0; i < parzellePath.length; i++) {
        let tmpArray = new Array();
        tmpArray.push(parzellePath.getAt(i).lat());
        tmpArray.push(parzellePath.getAt(i).lng());

        parzeleArr[0].push(tmpArray);
    }

    let tmpArray = new Array();
    tmpArray.push(parzellePath.getAt(0).lat());
    tmpArray.push(parzellePath.getAt(0).lng());

    parzeleArr[0].push(tmpArray);

    appRoot.grundrissMove(parzeleArr);
}

/**
 * Draw polygon (grundriss) on google map
 * @param  array polygonArr
 * @return null
 */
export function drawPolygonParzelle(polygonArr)
{

    console.log("map drawPolygonParzelle()", polygonArr);

    if (polygonArr == undefined) {
        return false;
    }
    if (parzellePolygon) {
        parzellePolygon.setMap(null);
    }

    var points=[];
    for(var i=0; i<polygonArr.length; i++) {
        points.push(new google.maps.LatLng(polygonArr[i]['lat'], polygonArr[i]['lng']));
    }
    // Remove Last Point da doppelt
    points.pop();

    // Construct the polygon.
    parzellePolygon = new google.maps.Polygon({
        paths: points,
        strokeColor: '#00ff3c',
        strokeOpacity: 0.8,
        strokeWeight: 4,
        fillColor: '#00ff3c',
        fillOpacity: 0,
        editable: true
    });

    parzellePolygon.getPaths().forEach(function(path, index){

        google.maps.event.addListener(path, 'set_at', function(){
            polyChanged2();
        });

        google.maps.event.addListener(path, 'insert_at', function (event) {
            polyChanged2();
        });

        google.maps.event.addListener(path, "remove_at", function(){
            polyChanged2();
          });

      });

    parzellePolygon.setMap(map);
    parzellePolygon.addListener("bounds_changed", polyChanged2);
}

export function parseParzelle(st)
    {

        console.log("##########",typeof st)
        if (typeof st == 'object') {
            return st[0];
        }
        var newParzelle = st.split("((")[1];
        newParzelle = newParzelle.replace('))','');
        newParzelle = newParzelle.split(",");
        var parzeleArr = new Array();

        for (var i = 0; i < newParzelle.length; i++) {
            var parzellePointarr = new Array();
            var parz = newParzelle[i].split(" ");
            parzellePointarr.push(parz[0]);
            parzellePointarr.push(parz[1]);
            parzeleArr.push(parzellePointarr)
        }

        return parzeleArr;
    }

export function isDefaultGrundrissPolygon(vertices)
    {

        if(vertices.length != 5) {
            return false;
        }

        var distance = 0;

        distance = calcCrow(vertices[0][0], vertices[0][1], vertices[1][0], vertices[1][1]);

        if(distance == 0.0299114353) {
            return true;
        }

        return false;

    }

     //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
    function calcCrow(lat1, lon1, lat2, lon2)
    {
      var R = 6371; // km
      var dLat = toRad(lat2-lat1);
      var dLon = toRad(lon2-lon1);
      var lat1 = toRad(lat1);
      var lat2 = toRad(lat2);

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c;
      return Number(d.toFixed(10));
    }

    // Converts numeric degrees to radians
    function toRad(Value)
    {
        return Value * Math.PI / 180;
    }

export function getParzellePolygon() {
    var polygonPath = parzellePolygon.getPath();


    return polygonPath;
}

// Get Lat Long von einem Google Polygon
// Input: Polygon Pfad (Google API: polygon.getPath()), Output: Array mit Lat Long
export function getLatLongFromPolygon(polyPfad) {

    var polyLatLong = [];
    for (var i =0; i < polyPfad.getLength(); i++) {
        var xy = polyPfad.getAt(i);
        var coordLatLong = [];
        coordLatLong[0] = xy.lat();
        coordLatLong[1] = xy.lng();
        polyLatLong.push(coordLatLong);
    }

    return polyLatLong;
}
