<template>
    <div style="width: 100%; height: 400px; "></div>
</template>

<script>



export default
{
    name: 'GoogleMap',
    props: {
        latProp: Number,
        lonProp: Number,
        polygonProp: String
    },
    data () {
        return {
            lat: null,
            lon: null,
            polygon: null,
        }
    },
    async mounted() {

        console.log("mounted google maps component");

        var Maps = require('../googleMaps.js');
        try {

            const google = await Maps.init();
            console.log("map const google", google);

            this.lat = parseFloat(this.latProp);
            this.lon = parseFloat(this.lonProp);
            if(typeof this.polygonProp != 'undefined') {
                this.polygon = JSON.parse(this.polygonProp);
            }
            console.log("latProp, lonProp", this.latProp, this.lonProp)
            console.log("this.polygonProp", this.polygonProp)
            console.log("this.polygonProp polygon", this.polygon)

            if(this.lat != 0 && this.lon != 0) {

                // Maps.firstLoadMap(this.$el, this.lat, this.lon);
                Maps.firstLoadMap(this.$el, this.lat, this.lon).then(() => {
                    // firstLoadMap Done
                    Maps.drawPolygonParzelle(this.polygon);
                });

            }

        } catch (error) {
            console.error(error);
        }
    },
    watch: {
        // lat: function(newVal, oldVal) {
        //     console.log("lat changed", newVal, oldVal);
        // },
        // lon: function(newVal, oldVal) {
        //     console.log("lon changed", newVal, oldVal);
        // },
    },
    methods: {
        loadMap: function(lat, lon, polygon)
        {
            console.log("Load Map",this.$el, lat, lon);
            var Maps = require('../googleMaps.js');
            // Maps.firstLoadMap(this.$el, lat, lon);
            Maps.firstLoadMap(this.$el, this.lat, this.lon, polygon).then(() => {
                // firstLoadMap Done
            });
        },
        drawParzelle: function(parzelle, grundriss = undefined)
        {
            var Maps = require('../googleMaps.js');

            Maps.drawPolygonParzelle(parzelle);
            return;

            if (parzelle && grundriss == undefined) {
                if( parzelle.length !== 0) {
                    if (typeof parzelle.SHAPE != 'undefined') {
                        var parsedParzelle = Maps.parseParzelle(parzelle.SHAPE);
                    } else {
                        var parsedParzelle = Maps.parseParzelle(parzelle);
                    }
                     // Parzelle anzeigen oder wurde die Parzelle von hand verändert und dies anzeigen?
                    Maps.drawPolygonParzelle(parsedParzelle);
                }
            } else {
                    Maps.drawPolygonParzelle(parzelle);
            }


        },
        cutBuilding: function(polygon)
        {
            console.log("maps cutBuilding", polygon);
            console.log(window.player);
            window.player.getBuildingsFromPolygon(polygon, true);

            /*
            this.bildingCuted = true;

            var Maps = require('../googleMaps.js');

            var polyPath = Maps.getParzellePolygon();
            var polyPathLatLong = Maps.getLatLongFromPolygon(polyPath);

            // Erster Punkt am Ende anfügen
            polyPathLatLong.push(polyPathLatLong[0]);
            this.cutPoly = [polyPathLatLong];

            this.$refs.BuildingPlayer.cut(this.cutPoly);
            */
        }
    }
};
</script>

<style>

</style>
