<template>
    <div id="betriebsenergie">
        <div class="block">
            <label class="mb-2" for="strasse">{{ __('project.was_ist_vorhaben') }}</label><br>
            <div class="btn-group flex-wrap mb-4 btn-group-mobile" id="bauvorhaben" role="group" aria-label="Basic radio toggle button group">
                <input
                    v-model="bauvorhaben"
                    value="3"
                    @change="bauvorhabenChanged(3)"
                    type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off"
                >
                <label class="btn btn-outline-primary align-middle" for="btnradio3">{{ __('project.altbau') }}</label>

                <input
                    v-model="bauvorhaben"
                    value="1"
                    @change="bauvorhabenChanged(1)"
                    type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"
                >
                <label class="btn btn-outline-primary align-middle" for="btnradio1">{{ __('project.sanierung') }}</label>

                <input
                    v-model="bauvorhaben"
                    value="0"
                    @change="bauvorhabenChanged(0);"
                    type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
                >
                <label class="btn btn-outline-primary align-middle" for="btnradio2">{{ __('project.neubau') }}</label>
            </div>

            <!-- Neubau -->
            <div v-show="bauvorhaben == 0">
                <div class="form-group mb-4">
                    <label class="mb-2" for="strasse">{{ __('project.strasse') }}</label>
                    <input
                        type="text"
                        id="strasseNeubau"
                        ref="strasseNeubau"
                        v-model="strasseNeubau"
                        autocomplete="do-not-autofill"
                        class="form-control pflichtfeld"
                        @change="changeAdresseNeubau(); updateFormValidationForThisField($event)"
                        >
                    </div>
                    <div class="form-group" style="margin-bottom: 40px;">
                        <label class="mb-2" for="plzNeubau" style="width: 110px;">PLZ</label>
                        <label class="mb-2" for="ortNeubau">{{ __('project.ort') }}</label>
                        <div>
                            <input
                                type="number"
                                min="1000" max="9999"
                                id="plzNeubau"
                                v-model="plzNeubau"
                                autocomplete="do-not-autofill"
                                class="form-control pflichtfeld"
                                style="display: inline; width: 100px; margin-right: 10px;"
                                @change="changeAdresseNeubau(); updateFormValidationForThisField($event)"
                            >
                            <input
                                type="text"
                                id="ortNeubau"
                                v-model="ortNeubau"
                                autocomplete="do-not-autofill"
                                class="form-control pflichtfeld"
                                style="display: inline; width: calc(100% - 110px);"
                                @change="changeAdresseNeubau(); updateFormValidationForThisField($event)"
                            >
                    </div>
                </div>
            </div>

            <!-- Sanierung -->
            <div v-show="bauvorhaben >= 1">

                <!-- Adresssuche -->
                <div class="form-group mb-4" style="position: relative;">
                    <label class="mb-2" for="adresse">{{ __('project.adresse') }}</label>
                    <input
                        type="text"
                        id="adresse"
                        ref="adresse"
                        v-model="adresse"
                        autocomplete="do-not-autofill"
                        style="display: inline;"
                        class="form-control"
                    >
                    <!-- Disable Adresse solange Neubau/Sanierung noch nicht gewählt wurde -->
                    <div class="hider" v-if="adresseDisabled"></div>
                </div>

                <div style="position: relative;" v-show="hide3d == 0" class="row">

                    <!-- 3D Modell -->
                    <div class="col col-md-6">
                        <div id="building" class="mb-4"></div>
                    </div>
                    <!-- Google Maps -->
                    <div class="col col-md-6" v-if="mapVisible" id="modell-anpassen-text">
                        <div style="height: 1px; width: 250px;"></div>
                        <google-map :latProp="this.lat" :lonProp="this.lon" :polygonProp="this.mapPolygon" ref="GoogleMap"></google-map>
                    </div>

                    <!-- Show hider if adresse is not selected -->
                    <div class="hider" v-if="!adresseSelected"></div>

                </div>

                <!-- Kein 3D Modell gefunden, zeige Vogel, wieso auch immer xD -->
                <div style="position: relative;" v-show="hide3d == 1">
                    <img src="/images/abs_bird.jpg" style="width: 30px;" class="mb-3">
                </div>

                <div class="row">
                    <div class="col col-md-6">
                        {{ __('project.modell_korrekt') }}
                        <span
                            class="link"
                            @click="showMap()"
                        >
                            <!-- data-bs-target="#modellAnpassenModal" -->
                            <!-- data-bs-toggle="modal" -->
                            {{ __('project.modell_anpassen') }}
                        </span>
                    </div>
                    <div class="col col-md-6" v-if="mapVisible">
                        {{ __('project.linie_veraendern') }}
                    </div>
                </div>

            </div>

            <div style="position: relative;">

                <div v-if="bauvorhaben >= 1" class="mt-5">
                    <span>{{ __('project.felder_pruefen') }}&nbsp;&nbsp;</span>
                    <div
                            class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                            :title="tooltipDiv+__('project.tooltip_datenherleitung')+tooltipDivEnd"
                            style="position: relative; display: inline; right: auto;"
                            >
                            <img src="/images/info.png" width="17">
                    </div>
                </div>

                <div class="form-group mb-4" v-show="bauvorhaben >= 1">
                    <label class="mb-2" for="baujahr">{{ __('project.baujahr') }}</label>
                    <input
                        type="text"
                        id="baujahr"
                        v-model="baujahr"
                        @change="saveProject($event)"
                        class="form-control pflichtfeld"
                    >
                </div>

                <div class="form-group mb-4">
                <label class="mb-2" for="energiebezugsflaeche">
                    {{ __('project.wohnflaeche') }} (m²)
                    <div
                        class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-placement="bottom" data-html="true"
                        :title="tooltipDiv+__('project.tooltip_gesamtflaeche')+tooltipDivEnd"
                    >
                        <img src="/images/info.png" width="17">
                    </div>
                </label>
                <input
                        type="text"
                        id="energiebezugsflaeche"
                        v-model="energiebezugsflaeche"
                        @change="saveProject($event)"
                        class="form-control pflichtfeld"
                    >
                </div>

                <div class="form-group mb-4">
                    <label class="mb-2" for="heizsystem">
                        {{ __('project.heizsystem') }} <span v-if="bauvorhaben >= 1">{{ __('project.bestehend') }}</span>
                        <div
                            class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                            :title="tooltipDiv+__('project.tooltip_heizsystem')+tooltipDivEnd"
                        >
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                    <select
                        v-model="heizsystem"
                        @change="saveProject($event); heizungseinheit()"
                        class="form-select pflichtfeld"
                        id="heizsystem"
                    >
                        <option value="" disabled selected>{{ __('project.bitte_waehlen') }}</option>
                        <option value="1">{{ __('project.luftwaermepumpe') }}</option>
                        <option value="2">{{ __('project.erdwaermepumpe') }}</option>
                        <option value="8">{{ __('project.fernwaerme') }}</option>
                        <option value="3">{{ __('project.holzpellets') }}</option>
                        <option value="4">{{ __('project.oel') }}</option>
                        <option value="5">{{ __('project.gas') }}</option>
                        <option value="6" v-if="bauvorhaben >= 1">{{ __('project.stueckholz') }}</option>
                        <option value="7" v-if="bauvorhaben >= 1">{{ __('project.elektrospeicher') }}</option>
                    </select>
                </div>

                <div v-show="bauvorhaben >= 1" class="form-group mb-4">
                    <label class="mb-2" for="verbrauch">
                        {{ __('project.verbrauch_heizung') }} ({{ heizsystemEinheit }}/{{ __('project.jahr') }})
                        <!-- Gas -->
                        <div
                            v-if="heizsystem == 5"
                            class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                            :title="tooltipDiv+__('project.tooltipVerbrauchIntro')+__('project.tooltipVerbrauchGas')+__('project.tooltipVerbrauchOutro')+tooltipDivEnd"
                        >
                            <img src="/images/info.png" width="17">
                        </div>
                        <!-- Oel -->
                        <div
                            v-else-if="heizsystem == 4"
                            class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                            :title="tooltipDiv+__('project.tooltipVerbrauchIntro')+__('project.tooltipVerbrauchOel')+__('project.tooltipVerbrauchOutro')+tooltipDivEnd"
                        >
                            <img src="/images/info.png" width="17">
                        </div>
                        <!-- WP / WP Erdsonde / xFernwärme -->
                        <div
                            v-else-if="heizsystem == 1 || heizsystem == 2 || heizsystem == 8"
                            class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                            :title="tooltipDiv+__('project.tooltipVerbrauchIntro')+__('project.tooltipVerbrauchWp')+__('project.tooltipVerbrauchOutro')+tooltipDivEnd"
                        >
                            <img src="/images/info.png" width="17">
                        </div>
                        <!-- Holzpellets -->
                        <div
                            v-else-if="heizsystem == 3"
                            class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                            :title="tooltipDiv+__('project.tooltipVerbrauchIntro')+__('project.tooltipVerbrauchPellets')+__('project.tooltipVerbrauchOutro')+tooltipDivEnd"
                        >
                            <img src="/images/info.png" width="17">
                        </div>
                        <!-- Stückholz -->
                        <div
                            v-else-if="heizsystem == 6"
                            class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                            :title="tooltipDiv+__('project.tooltipVerbrauchIntro')+__('project.tooltipVerbrauchStueckholz')+__('project.tooltipVerbrauchOutro')+tooltipDivEnd"
                        >
                            <img src="/images/info.png" width="17">
                        </div>
                        <!-- Else -->
                        <div
                            v-else
                            class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                            :title="tooltipDiv+__('project.tooltipVerbrauchIntro')+__('project.tooltipVerbrauchOutro')+tooltipDivEnd"
                        >
                            <img src="/images/info.png" width="17">
                        </div>

                        <!-- <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innovation_tooltip1')+tooltipDivEnd">
                            <img src="/images/info.png" width="17">
                        </div> -->

                    </label>
                    <input
                            type="text"
                            id="verbrauch"
                            v-model="verbrauch"
                            @change="saveProject($event)"
                            class="form-control pflichtfeld"
                        >
                </div>

                <!-- Bei Sanierung Heizsystem neu abfragen -->
                <div class="form-group mb-4" v-if="bauvorhaben == 1">
                    <label class="mb-2" for="heizsystemNeu">
                        {{ __('project.heizsystem_neu') }}
                        <div
                            class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                            :title="tooltipDiv+__('project.tooltip_heizsystem_zukunft')+tooltipDivEnd"
                        >
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                    <select
                        v-model="heizsystemNeu"
                        @change="saveProject($event); heizungseinheit()"
                        class="form-select pflichtfeld"
                        id="heizsystemNeu"
                    >
                        <option value="" disabled selected>{{ __('project.bitte_waehlen') }}</option>
                        <option value="99">{{ __('project.kein_heizungsersatz') }}</option>
                        <option value="1">{{ __('project.luftwaermepumpe') }}</option>
                        <option value="2">{{ __('project.erdwaermepumpe') }}</option>
                        <option value="8">{{ __('project.fernwaerme') }}</option>
                        <option value="3">{{ __('project.holzpellets') }}</option>
                        <option value="4">{{ __('project.oel') }}</option>
                        <option value="5">{{ __('project.gas') }}</option>
                        <!-- <option value="6">{{ __('project.stueckholz') }}</option> -->
                        <!-- <option value="7" v-if="bauvorhaben >= 1">{{ __('project.elektrospeicher') }}</option> -->
                    </select>
                </div>

                <div class="form-group mb-4">
                    <label class="mb-2" for="warmwasser">
                        {{ __('project.warmwassererzeugung') }}
                        <div
                            class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                            :title="tooltipDiv+__('project.tooltip_warmwasser')+tooltipDivEnd"
                        >
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                    <select
                        v-model="warmwasser"
                        @change="saveProject($event)"
                        class="form-select pflichtfeld"
                        id="warmwasser"
                    >
                        <option value="" disabled selected>{{ __('project.bitte_waehlen') }}</option>
                        <option value="0">{{ __('project.elektroboiler') }}</option>
                        <option value="1">{{ __('project.luftwaermepumpe') }}</option>
                        <option value="2">{{ __('project.erdwaermepumpe') }}</option>
                        <option value="8">{{ __('project.fernwaerme') }}</option>
                        <option value="3">{{ __('project.holzpellets') }}</option>
                        <option value="4">{{ __('project.oel') }}</option>
                        <option value="5">{{ __('project.gas') }}</option>
                        <option value="6" v-if="bauvorhaben >= 1">{{ __('project.stueckholz') }}</option>
                        <option value="7" v-if="bauvorhaben >= 1">{{ __('project.elektrospeicher') }}</option>
                    </select>
                </div>

                <div class="form-group mb-4">
                    <label class="mb-2" for="thermie">
                        {{ __('project.solarthermie') }}
                        <!-- <div
                            class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                            title='<div class="tooltip_value">Wodurch wird das Warmwasser erzeugt?</div>'
                        >
                            <img src="/images/info.png" width="17">
                        </div> -->
                    </label>
                    <select
                        v-model="thermie"
                        @change="saveProject($event)"
                        class="form-select pflichtfeld"
                        id="thermie"
                    >
                        <option value="0">{{ __('project.nein') }}</option>
                        <option value="1">{{ __('project.ja') }}</option>
                    </select>
                </div>

                <div class="form-group mb-4">
                    <label class="mb-2" for="photovoltaik">
                        {{ __('project.photovoltaik') }}
                    </label>
                    <select
                        v-model="photovoltaik"
                        @change="saveProject($event)"
                        class="form-select pflichtfeld"
                        id="photovoltaik"
                    >
                        <option value="0">{{ __('project.photovoltaik_mini') }}</option>
                        <option value="1">{{ __('project.photovoltaik_s') }}</option>
                        <option value="2">{{ __('project.photovoltaik_l') }}</option>
                    </select>
                </div>

                <div class="form-group mb-4">
                <label class="mb-2" for="bewohner">
                    {{ __('project.bewohner') }}
                    <div
                        class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-placement="bottom" data-html="true"
                        :title="tooltipDiv+__('project.bewohner_tooltip')+tooltipDivEnd"
                    >
                        <img src="/images/info.png" width="17">
                    </div>
                </label>
                <input
                        type="text"
                        id="bewohner"
                        v-model="bewohner"
                        @change="saveProject($event)"
                        class="form-control pflichtfeld"
                    >
                </div>

                <div v-show="bauvorhaben == 0 || neubauWihout3d == 1" class="form-group mb-4">
                    <label class="mb-2" for="baustandard">
                        {{ __('project.baustandard') }}
                    </label>
                    <select
                        v-model="baustandard"
                        @change="saveProject($event)"
                        class="form-select pflichtfeld"
                        id="baustandard"
                    >
                        <option value="" disabled selected>{{ __('project.bitte_waehlen') }}</option>
                        <option value="1">{{ __('project.standard_erfuellt') }}</option>
                        <option value="2">{{ __('project.standard_uebertroffen') }}</option>
                    </select>
                </div>

                <!-- blaue Box Platzhalter -->
                <div class="points-box mb-2" style="position: relative;">
                    <p class="title mb-2">{{ __('project.betriebsenergie_ergebnis') }}</p>
                    <div v-if="apiLoaderMini == false">
                        <p v-if="showRating" class="points mb-0">{{ betriebsenergie_rating }} {{ __('project.von') }} 10</p>
                        <p v-else class="points mb-0">-</p>
                    </div>
                    <div v-else>
                        <p class="points mb-0">&nbsp;</p>
                    </div>
                    <div
                        v-if="apiLoaderMini == true"
                        style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background-color: white; opacity: 0.5;"
                    >
                        <div
                        class="spinner-border" role="status"
                        style="position: absolute; left: 30px; top: 55px; width: 23px; height: 23px;"
                        ></div>
                    </div>


                </div>

                <!-- Show hider if API Response not jet ricieved -->
                <div class="hider" v-if="!apiResponseRecieved">
                    <div
                        v-if="apiLoader"
                        class="spinner-border" role="status"
                        style="position: absolute; left: 50%; top: 100px;"
                    ></div>
                </div>

            </div>
        </div>

        <!-- Modell anpassen Modal -->
        <div class="modal fade modals modellAnpassenModal" id="modellAnpassenModal" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header align-items-center">

                        <div class="modal-title">{{ __('project.notes') }}</div>

                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <!-- Maps Component -->
                        <!-- <google-map :latProp="this.lat" :lonProp="this.lon" :polygonProp="this.parzelle" ref="GoogleMap"></google-map> -->

                        <div class="buttons mt-5">
                            <div>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ __('project.abbrechen') }}</button>
                            </div>

                            <div>
                                <button type="button" v-on:click="setNotes()" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>

/*
Recherche AVG Heizölverbrauch

    https://www.immoga.ch/immobilien-kanton-bern/-heizkosten-und-%C3%B6lverbrauch-berechnen
    Avg Schweiz 17l Öl m2 x140m2 = 2'380
    Renoviert: 5-10l 	= 700 - 1'400
    Minergie 3.8l		= 532

    https://www.energieheld.ch/renovation/nachhaltig-leben/energieverbrauch#:~:text=Zur%20Berechnung%20wird%20ein%20durchschnittlicher,im%20Jahr%20exklusive%20Warmwasser%20ergibt.
    Altbau 29'000	= 2'900
    Saniert 11'000	= 1'100
    Minergie 6'000	= 600

    https://www.energiesparen-im-haushalt.de/energie/bauen-und-modernisieren/modernisierung-haus/heizung-modernisieren/heizungsanlage-erneuern/oelheizung-erneuern/heizoelverbrauch-durchschnitt.html
    Avg (Neu/Alt) ohne WW
    13.6 x 140 = 1'900
    Max 20l, Neuer 10l

    Pellets
    https://www.kwb.net/de-ch/heizwissen/service-komfort/brennstoffbedarf-einer-pelletheizung/#:~:text=Bei%20einem%20Haus%20mit%20150,j%C3%A4hrlichen%20Pelletbedarf%20von%203.000%20Kilogramm.
    50 Watt x 150 m² = 7.500 Watt (7,5kW)
    7,5 kW x 400 kg Pellets = 3.000 kg Pellets pro Jahr

    Stückholz
    https://www.hargassner.com/de-de/news/scheitholzheizung-verbrauch/
    16rm = 10ster

*/

import Bloodhound from 'corejs-typeahead/dist/bloodhound';
import typeahead from 'corejs-typeahead/dist/typeahead.jquery';
import { store } from '../store.js'
import GoogleMaps from "./googleMaps";

export default {
    props: {
        buildingData: String
    },
    component: {
        'google-map': GoogleMaps
    },
    data() {
        return {
            store,
            bauvorhaben: '', // 0 = Neubau, 1 = Sanierung, 3 = Altbau
            neubauWihout3d: false,
            adresse: '',
            strasseNeubau: '',
            plzNeubau: '',
            ortNeubau: '',
            // lat: 47.69398866054031,
            // lon: 8.6564504059219,
            lat: 0,
            lon: 0,
            mapPolygon: "",
            mapVisible: false,
            mapLoaded: false,
            egid: null,
            suggestions: false,
            baujahr: '',
            energiebezugsflaeche: '',
            heizsystem: '',
            heizsystemEinheit: 'kWh',
            heizsystemNeu: '',
            // heizsystemEinheit: 'kWh',
            verbrauch: '',
            warmwasser: '',
            thermie: 0,
            photovoltaik: 0,
            bewohner: '',
            baustandard: '',
            hide3d: 0, // True wenn keine 3D Daten vorhanden. Dann wie Neubau behandeln.
            adresseDisabled: true, // Disable Address field until Neubau/Sanierung is selected, show hider
            adresseSelected: false, // Address field filled, disable hider
            apiResponseRecieved: false, // API Response recieved, disable hider
            apiLoader: false, // API Loader, show spinner
            apiLoaderMini: false, // API Loader, spinner nur für das Rating unten
            cutBuildingHappened: false, // Wird auf true gesetzt, wenn das Gebäude mit der grünen Linie geschnitten wird. Nach dem PlayerLoadCallback wieder auf false setzen
            betriebsenergie_rating: 0,
            showRating: false,
            tooltipDiv: '<div class="tooltip_value">',
            tooltipDivEnd: '</div>'
        }
    },
    watch: {
        store: {
            handler(newValue, oldValue) {
                // Note: `newValue` will be equal to `oldValue` here
                // on nested mutations as long as the object itself
                // hasn't been replaced.
                // console.log("store changed store.validateData", store.validateData);

                // store.validateData == true --> the form will be validated (Betriebsenergie)
                if(store.validateData) {
                    this.weiterButtonClicked();
                    store.validateData = false;
                }
            },
            deep: true
        }
    },
    async mounted() {
        // console.log("Mounted Component Betriebsenergie");
        // console.log(window.siteUrl);
        // console.log(store.csrfToken);
        // console.log(this.buildingData);
        console.log("Mounted buildingData", JSON.parse(this.buildingData));

        // Assign Props Building Data to Data Variables
        this.assignData(JSON.parse(this.buildingData));
        this.heizungseinheit();

        // Initi Player
        await this.initializePlayer();

        // If adresse is already set, load 3D Building - Project already exists
        if(this.adresse && (this.bauvorhaben == 1 || this.bauvorhaben == 3)) {
            // console.log("adresse is defined");

            if(this.mapPolygon != '') {
                // Load from Polygon

                let poly = this.preparePolygonForPlayer(this.mapPolygon);
                if(poly != false) {
                    // console.log("getBuildingsFromPolygon", poly);
                    window.player.getBuildingsFromPolygon(poly, true);
                } else {
                    window.player.getBuilding(this.lat, this.lon, true);
                }
            } else {
                // Load from Lat/Lon
                // console.log("getBuilding")
                window.player.getBuilding(this.lat, this.lon, true);
            }
        }
        // else if(
        //     this.bauvorhaben == 0 &&
        //     (this.isNotEmpty(this.strasseNeubau) && this.isNotEmpty(this.plzNeubau) && this.isNotEmpty(this.ortNeubau))
        // ) {
        //     this.adresse = this.strasseNeubau + ', ' + this.plzNeubau + ' ' + this.ortNeubau;
        // }

        // Initialize Autocomplete Address Search Typeahead
        this.addressSearch(window.player);

        // Delay 0.5s. Beim Seite laden wird sonst der Weiter Button nicht aktiviert. Verstehe nicht wieso.
        setTimeout(() => {
            // Validate Form
            this.validateNextButton();
        }, 100);

         this.updateTooltip();

    },
    methods: {
        updateTooltip: function() {

            setTimeout(() => {
               $('[data-toggle="tooltip"]').tooltip({html:true});
            }, 500);

        },
        // Workaround falls Player Script laden sehr lange dauert.
        initializePlayer() {

            return new Promise((resolve, reject) => {
                if (typeof buildingPlayer === "function") {
                    // Workaround:
                    // Player kann nicht in einer Komponenten-Variabel sein (data property) da es sonst einen Error gibt, weil Vue die Inhalte 'watcht'
                    // Daher global oder immer den Player mitübergeben
                    // https://stackoverflow.com/questions/65693108/threejs-component-working-in-vuejs-2-but-not-3
                    window.player = new buildingPlayer({
                        googleMapsKey: "AIzaSyDM6Uqy3K89O1fXCi3zfQ8zsOtojqXeS8I", // required
                        container: "building",
                        minWidth: 300,
                        minHeight: 300,
                        zoomControls: false,
                        rotateControls: false,
                        roofSelectable: 0,
                        showBestRoof: false,
                        screenshotImgType: "jpg",
                        mapType: 'swisstopo-image',
                        loadCallback: this.playerLoadCallback,
                        roofClickCallback: this.playerRoofClickCallback
                    });
                    console.log("mounted, window.player defined:", window.player);

                    resolve();
                } else {
                    // Wait and try again
                    setTimeout(() => {
                        console.log("timeout done");
                        window.player = new buildingPlayer({
                            googleMapsKey: "AIzaSyDM6Uqy3K89O1fXCi3zfQ8zsOtojqXeS8I", // required
                            container: "building",
                            minWidth: 300,
                            minHeight: 300,
                            zoomControls: false,
                            rotateControls: false,
                            roofSelectable: 0,
                            showBestRoof: false,
                            screenshotImgType: "jpg",
                            mapType: 'swisstopo-image',
                            loadCallback: this.playerLoadCallback,
                            roofClickCallback: this.playerRoofClickCallback
                        });
                        console.log("mounted, window.player defined:", window.player);

                        resolve();
                    }, 500);

                }

            });
        },
        // Write Props buildingData to Data Variables
        assignData: function(buildingData) {
            // console.log("assignData(), buildingData",buildingData);
            this.bauvorhaben = buildingData.bauvorhaben;
            this.neubauWihout3d = buildingData.neubauWihout3d;
            this.adresse = buildingData.adresse;
            this.strasseNeubau = buildingData.strasse_neubau;
            this.plzNeubau = buildingData.plz_neubau;
            this.ortNeubau = buildingData.ort_neubau;
            if(buildingData.lat == '') {
                this.lat = 0;
            } else {
                this.lat = buildingData.lat;
            }
            if(buildingData.lon == '') {
                this.lon = 0;
            } else {
                this.lon = buildingData.lon;
            }
            // console.log("assignData map_polygon", buildingData.map_polygon);
            this.mapPolygon = buildingData.map_polygon;
            this.grundriss = buildingData.grundriss;
            this.baujahr = buildingData.baujahr;
            this.energiebezugsflaeche = buildingData.energiebezugsflaeche;
            this.heizsystem = buildingData.heizsystem;
            if(buildingData.heizsystem === null) { // Bei select kommt von DB am anfang null, soll aber select option '' auswählen
                this.heizsystem = '';
            }
            this.energiebezugsflaeche = buildingData.energiebezugsflaeche;
            this.heizsystemNeu = buildingData.heizsystemNeu;
            if(buildingData.heizsystemNeu === null) { // Bei select kommt von DB am anfang null, soll aber select option '' auswählen
                this.heizsystemNeu = '';
            }
            this.verbrauch = buildingData.verbrauch;
            this.warmwasser = buildingData.warmwasser;
            if(buildingData.warmwasser === null) {
                this.warmwasser = '';
            }
            this.thermie = buildingData.thermie;
            this.photovoltaik = buildingData.photovoltaik;
            this.bewohner = buildingData.bewohner;
            this.baustandard = buildingData.baustandard;
            if(buildingData.baustandard === null) {
                this.baustandard = '';
            }
            this.betriebsenergie_rating = buildingData.betriebsenergie_rating;

            this.energiekennzahl = buildingData.energiekennzahl;
            // Wenn Energiekennzahl existiert kann das Rating angezeigt werden
            if(this.energiekennzahl > 0) {
                this.showRating = true;
            }

            // If the address is empty: it is a new project, disable fields
            // If the address is already set: it is an exisiting project, enable fields
            if(this.adresse == '') {
                this.adresseDisabled = true;
                this.adresseSelected = false;
                this.apiResponseRecieved = false;
            } else {
                this.adresseDisabled = false;
                this.adresseSelected = true;
                this.apiResponseRecieved = true;
            }

            // Heizungseinheit updaten
            this.heizungseinheit();

        },
        /**
         * Bei Neubau setzt sich die Adresse aus den Feldern Strasse, PLZ und Ort zusammen
         * In dieser Funktion wird die Adresse das Projekt gespeichert wenn die Adresse komplett ist
         * Je nachdem Validation aufrufen (wenn nachträglich geändert wird)
         */
        changeAdresseNeubau: function() {

            // If strasseNeubau, plzNeubau and ortNeubau are not an empty String, call saveProject()
            if(this.isNotEmpty(this.strasseNeubau) && this.isNotEmpty(this.plzNeubau) && this.isNotEmpty(this.ortNeubau)) {
                // console.log("changeAdresseNeubau saveProject", this.strasseNeubau, this.plzNeubau, this.ortNeubau);
                this.saveProject();
            }
            // else {
            //     this.validateNextButton();
            // }

        },
        /**
         * Show Heating Unit based on Heating Type
         */
        heizungseinheit: function() {

            // Bestehende Heizung
            if(this.heizsystem == 1 || this.heizsystem == 2 || this.heizsystem == 5 || this.heizsystem == 7 || this.heizsystem == 8) {
                this.heizsystemEinheit = 'kWh';
            } else if(this.heizsystem == 3) {
                this.heizsystemEinheit = 'kg';
            } else if(this.heizsystem == 4) {
                this.heizsystemEinheit = 'Liter';
            } else if(this.heizsystem == 6) {
                this.heizsystemEinheit = 'Ster';
            }

            $('[data-toggle="tooltip"]').tooltip({html:true});

        },
        /**
         * addressSearch
         * Autocomplete Adresssuche Typeahead, Trigger Load 3D Building, Save/Create Project
         * @param {*} player - Player Instance
         */
        addressSearch: function(player) {

            // this.imageTimer = Date.now();

            this.suggestions = new Bloodhound({
                limit: 30,
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: 'https://api3.geo.admin.ch/rest/services/api/SearchServer?searchText=%QUERY&type=locations&origins=address&limit=8',
                    wildcard: '%QUERY',
                    filter: function(locations) {
                        return locations.results;
                    }
                }
            });

            var addressInput = $('#adresse');

            addressInput.typeahead(
            {
                minLength: 1,
                highlight: true,
            },
            {
                name: 'suggestions',
                source: this.suggestions,
                limit: 5,
                display: function(item) {
                    return item.attrs.label.replace('<b>', '').replace('</b>', '');
                },
                templates: {
                    suggestion: (data) => {

                        return  '<div class="typehead-sugestion">' + data.attrs.label + '</div>' ;;
                    }
                }
            }
            ).on('typeahead:beforeclose', ($e) => {


            });

            addressInput.on('typeahead:select', (e, item) => {

                // console.log("Adresse ausgewählt");

                // Enable Form
                this.adresseSelected = true;

                // Hide Map
                this.resetMap();

                // this.q = item.attrs.label.replace('<b>', '').replace('</b>', '');
                this.lat = item.attrs.lat;
                this.lon = item.attrs.lon;

                //  this.$parent.getBuilding(this.lat, this.lon);
                // // this.bPlayer.getBuilding(this.lat, this.lon);

                this.egid = item.attrs.featureId;
                this.adresse = item.attrs.label.replace('<b>', '').replace('</b>', '');
                // this.disableInput = false;

                // console.log(this.lat, this.lon, this.egid);

                // Load Building
                window.player.getBuilding(this.lat, this.lon, true);

                // Get Base64 Image String and send it to the Backend to store it in public/storage
                /*
                // Besser später ausführen, hier kann es sein , dass Projekt noch nicht angelegt ist.
                var _this = this;
                setTimeout(function() {
                    _this.saveImage(player.getImage());
                }, 2000);
                */

                // Save/Create Project in Backend
                this.apiResponseRecieved = false;
                this.saveProject('adresseSanierung', player);

                // this.$store.state.sessionData.adresse = this.adresse;
                // this.$store.state.sessionData.lat = this.lat;
                // this.$store.state.sessionData.lon = this.lon;
                // this.$store.state.sessionData.egid = this.egid;
                // this.buildingsApiGwr(this.$store.state.sessionData.egid);
                // this.defaultModel = false;
                // $('#adress-input').removeClass('form-control-error');

                // this.showSuggestions = false;

                // this.modelChanged = true;
                // this.imageTimer = Date.now();

            });

        },
        setBuildingImage: function(image) {
            this.image = image;
        },
        // Player Callback, Called on Player Load
        playerLoadCallback: function(data) {
            console.log("playerLoadCallback()", data);
            if(data.status != 'ok') {
                this.hide3d = true;
                this.neubauWihout3d = 1;
            } else {
                this.hide3d = false;
                this.neubauWihout3d = 0;
                // this.energiebezugsflaeche = parseInt(data.buildingGroundArea); // Falsch: Energiebezugsfläche != Grundfläche
            }
            if(this.cutBuildingHappened == true) {
                this.cutBuildingHappened = false;
                this.calcEnergiebezugsflaeche(data.buildingGroundArea, data.buildingLevels, data.buildingRoofShape);
            }
        },
        // Player Roof Click Callback, Called on Roof Click
        playerRoofClickCallback: function(data) {
            // console.log(data);
        },
        // Save or Create Project
        saveProject: function(event = false, player = false) {

            console.log("---------- saveProject()", event);

            let mapPolygon = this.mapPolygon;

            // Adresse Sanierung changed
            if(event == 'adresseSanierung') {

                // If Adresse Sanierung changed, new GWR Data incoming. Disable Fields (Baujahr etc.)
                this.apiResponseRecieved = false;
                this.apiLoader = true;

            // Map Polygon change by User
            } else if(event == 'cutBuilding') {
                // mapPolygon = JSON.stringify(this.mapPolygon);
            } else if(event) {
                // console.log("saveProject", "event", event);
                // console.log("saveProject", "event.target", event.target.classList);

                if(event.target.classList.contains('form-error')) {
                    // console.log("has class form-error")
                    event.target.classList.remove("form-error");
                }

            }

            // this.apiResponseRecieved = false;
            // this.apiLoader = true;
            this.apiLoaderMini = true;

            var formData = {
                bauvorhaben: parseInt(this.bauvorhaben),
                neubauWihout3d: parseInt(this.neubauWihout3d),
                adresse: this.adresse,
                strasse_neubau: this.strasseNeubau,
                plz_neubau: this.plzNeubau,
                ort_neubau: this.ortNeubau,
                lat: this.lat,
                lon: this.lon,
                baujahr: parseInt(this.baujahr),
                energiebezugsflaeche: this.energiebezugsflaeche,
                heizsystem: parseInt(this.heizsystem),
                heizsystemNeu: parseInt(this.heizsystemNeu),
                verbrauch: parseInt(this.verbrauch),
                warmwasser: this.warmwasser,
                thermie: this.thermie,
                photovoltaik: this.photovoltaik,
                bewohner: parseInt(this.bewohner),
                baustandard: parseInt(this.baustandard)
            }

            if(typeof(mapPolygon) != 'undefined') {
                formData.map_polygon = mapPolygon;
                // console.log("formData.map_polygon", formData.map_polygon);
            }

            console.log("saveProject", "formData", formData);

            axios.post(siteUrl+"/betriebsenergie", {
                // _token: token,
                data: formData
            })
            .then(response => {
                console.log("saveProject Response", response.data);
                // this.ajaxRunning = false;
                // $('meta[name="csrf-token"]').attr('content', response.data.token)
                // this.$store.state.sessionData = response.data.data;
                store.csrfToken = response.data.token;
                this.bauvorhaben = response.data.bauvorhaben;
                this.neubauWihout3d = response.data.neubauWihout3d;
                this.strasseNeubau = response.data.strasse_neubau;
                this.plzNeubau = response.data.plz_neubau;
                this.ortNeubau = response.data.ort_neubau;
                this.lat = response.data.lat;
                this.lon = response.data.lon;
                // console.log("reponse.data.map_polygon", response.data.map_polygon)
                this.mapPolygon = response.data.map_polygon;

                if(typeof(this.$refs.GoogleMap) != 'undefined' && this.mapLoaded == false && this.mapVisible == true) {
                    // console.log("----------------------------- this.mapLoaded", this.mapLoaded)
                    this.mapLoaded = true;
                    this.$refs.GoogleMap.loadMap(this.lat, this.lon, this.mapPolygon); // Load Google Map (beim laden eines bestehenden Projektes)
                }

                this.baujahr = response.data.baujahr;
                if(event != 'cutBuilding') {
                    this.energiebezugsflaeche = response.data.energiebezugsflaeche;
                }
                this.heizsystem = response.data.heizsystem;
                this.heizungseinheit();
                if(response.data.heizsystem === null) { // Bei select kommt von DB am anfang null, soll aber select option '' auswählen
                    this.heizsystem = '';
                }
                this.heizsystemNeu = response.data.heizsystemNeu;
                if(response.data.heizsystemNeu === null) { // Bei select kommt von DB am anfang null, soll aber select option '' auswählen
                    this.heizsystemNeu = '';
                }
                this.verbrauch = response.data.verbrauch;
                this.warmwasser = response.data.warmwasser;
                if(response.data.warmwasser === null) {
                    this.warmwasser = '';
                }
                this.thermie = response.data.thermie;
                this.photovoltaik = response.data.photovoltaik;
                this.bewohner = response.data.bewohner;
                this.baustandard = response.data.baustandard;
                if(response.data.baustandard === null) {
                    this.baustandard = '';
                }

                // console.log("set betriebsenergie_rating", response.data.betriebsenergie_rating);
                // console.log("energiekennzahl: ", response.data.energiekennzahl);

                this.betriebsenergie_rating = response.data.betriebsenergie_rating;
                store.betriebsenergie_rating = response.data.betriebsenergie_rating;

                this.apiResponseRecieved = true;
                this.apiLoader = false;
                this.apiLoaderMini = false;

                // Wenn Energiekennzahl existiert kann das Rating angezeigt werden
                if(response.data.energiekennzahl > 0) {
                    this.showRating = true;
                } else {
                    this.showRating = false;
                }

                // 3D Bild beim ersten mal speichern
                if(event == 'adresseSanierung' || event == 'cutBuilding') {
                    // Get Base64 Image String and send it to the Backend to store it in public/storage
                    // this.saveImage(window.player.getImage());

                    var _this = this;
                    setTimeout(function() {
                        _this.saveImage(window.player.getImage());
                    }, 1500);

                }

                // Enable Next Button?
                this.validateNextButton();
            });
        },
        saveImage: function(imageString) {
            // console.log("saveImage()");

            var formData = {
                image: imageString,
            }

            axios.post(siteUrl+"/betriebsenergie/save3dImage", {
                data: formData
            })
            .then(response => {
                // console.log("saveImage Response", response.data);
            });

        },
        // Bei Auswahl Bauvorhaben
        // Type: 0 = Neubau, 1 = Sanierung
        bauvorhabenChanged: function(type) {
            // console.log('bauvorhabenChanged', type);

            this.resetMap();

            // Validate Next Button. Wichtig bei wenn der User zuerst alles ausfüllt und danach Bauvorhaben wechselt
            this.validateNextButton(1);

            this.adresseDisabled = false;

            // Neubau
            if(type === 0) {

                this.adresse = '';

                // Bei Neubau gibt es manche Systeme nicht die es bei Sanierung gibt
                if(this.heizsystem != '') {
                    this.heizsystem = 2;
                }

                // Wenn alles schon ausgefüllt ist, speichere/berechne das Projekt neu
                if(store.nextButtonDisabled === false) {
                    this.saveProject();
                    return;
                }

                // Set Cursor to Field strasse_neubau
                this.$nextTick(() => {
                    this.$refs.strasseNeubau.focus();
                })

            // Sanierung
            } else if(type === 1) {

                this.adresse = '';
                this.apiResponseRecieved = false;

                // Set Cursor to Field adresse
                this.$nextTick(() => {
                    this.$refs.adresse.focus();
                })

            } else if(type === 3) {

                this.adresse = '';
                this.heizsystemNeu = '';

                // Set Cursor to Field adresse
                this.$nextTick(() => {
                    this.$refs.adresse.focus();
                })
            }

        },
        /**
         * Validate Form to enable/disable "Weiter" Button
         */
        validateNextButton: function() {

            store.nextButtonDisabled = true;

            // If all criteria are met, enable next button
            console.log("validateNextButton:",
                "bauvorhaben:", this.bauvorhaben,
                "adresse:", this.adresse,
                "baujahr:", this.baujahr,
                "energiebezugsflaeche:", this.energiebezugsflaeche,
                "heizsystem bestehend:", this.heizsystem,
                "verbrauch:", this.verbrauch,
                "heizsystemNeu:", this.heizsystemNeu,
                "warmwasser:", this.warmwasser,
                "thermie:", this.thermie,
                "bewohner:", this.bewohner,
            );

            if(
                this.isNotEmpty(this.bauvorhaben) && this.isNotEmpty(this.energiebezugsflaeche) && this.isNotEmpty(this.heizsystem) && this.isNotEmpty(this.warmwasser) && this.isNotEmpty(this.bewohner))
            {
                this.bauvorhaben = parseInt(this.bauvorhaben);

                // Diese Felder sind nur bei NEUBAU pflicht
                if(this.bauvorhaben === 0) {
                    if(this.isNotEmpty(this.strasseNeubau) && this.isNotEmpty(this.plzNeubau) && this.isNotEmpty(this.ortNeubau) && this.isNotEmpty(this.baustandard)) {
                        // console.log(this.strasseNeubau, this.plzNeubau, this.ortNeubau);
                        store.nextButtonDisabled = false;
                    }
                // Diese Felder sind nur bei SANIERUNG pflicht
                } else if(this.bauvorhaben === 1 && this.isNotEmpty(this.adresse)) {

                    console.log("check ob verbrauch, baujahr, heizsystemNeu leer ist?", this.verbrauch, this.baujahr, this.heizsystemNeu);

                    if(this.isNotEmpty(this.verbrauch) && this.isNotEmpty(this.baujahr) && this.isNotEmpty(this.heizsystemNeu)) {
                        // console.log("verbrauch und baujahr ist nicht empty");
                        store.nextButtonDisabled = false;
                    } else {
                        console.log("verbrauch und baujahr ist empty, button bleibt disabled");
                    }
                // Diese Felder sind bei ALTBAU pflicht
                } else if(this.bauvorhaben === 3 && this.isNotEmpty(this.adresse)) {

                    // console.log("check2 ob verbrauch, baujahr leer ist?", this.verbrauch, this.baujahr);

                    if(this.isNotEmpty(this.verbrauch) && this.isNotEmpty(this.baujahr)) {
                        // console.log("verbrauch und baujahr ist nicht empty");
                        store.nextButtonDisabled = false;
                    } else {
                        // console.log("verbrauch und baujahr ist empty, button bleibt disabled");
                    }

                }

            }

            // console.log("store.nextButtonDisabled", store.nextButtonDisabled);

        },
        /**
         * Add red border to not filled fields (if visible)
         */
        showFormValidation: function() {

            $(".pflichtfeld").removeClass('form-error');

            // Loop over Elements with class .pflichtfeld
            // and add class .error if not visible and not empty
            var thisComponent = this;
            var firstFieldWithError = false;
            $('.pflichtfeld').each(function(i, obj) {

                // console.log(this.id, "is visible?", $(this).is(":visible"), "value:", $(this).val());

                if(
                    thisComponent.isNotEmpty($(this).val()) == false
                    && $(this).is(":visible")
                ) {
                    // console.log(this.id, "add form-error");
                    $(this).addClass('form-error');
                    if(firstFieldWithError == false) {
                        firstFieldWithError = this.id;
                    }
                }

            });

            if(firstFieldWithError) {
                document.getElementById(firstFieldWithError).scrollIntoView();
            }

        },
        /**
         * Remove red border from field
         * on input field update
         */
        updateFormValidationForThisField: function(event) {

            if(this.isNotEmpty(event.target.value)) {
                $("#"+event.target.id).removeClass('form-error');
            }

        },
        /**
         * Validate Form and
         * Show red border to not filled fields
         */
        weiterButtonClicked: function() {
            // console.log("weiterButtonClicked");

            this.validateNextButton();
            this.showFormValidation();

        },
        // Check if a variable is not an empty string, undefined or not null. 0 is allowed
        isNotEmpty: function(value) {
            if(value === null || value === '' || value === undefined) {
                // Its empty
                return false;
            } else {
                return true;
            }
        },
        // Check if a variable is an empty string, undefined or null. 0 is allowed
        isEmpty: function(value) {
            if(value === null || value === '' || value === undefined) {
                // Its empty
                return true;
            } else {
                return false;
            }
        },
        /**
         * Wird aufgerufen, wenn Map Polygon verändert wurde
         * 3D Modell wird am Polygon geschnitten
         * @param {*} polygon
         */
        cutBuilding: function(polygon)
        {
            // console.log("maps cutBuilding getBuildingsFromPolygon()", polygon);
            // console.log(window.player);
            window.player.getBuildingsFromPolygon(polygon, true);

            // console.log("this.mapPolygon", this.mapPolygon)
            this.mapPolygon = this.transformPolygon(polygon);
            // console.log("this.mapPolygon NEW", this.mapPolygon)
            this.mapPolygon = JSON.stringify(this.mapPolygon);
            // console.log("this.mapPolygon NEW", this.mapPolygon)

            this.cutBuildingHappened = true;
            this.saveProject('cutBuilding');
        },
        preparePolygonForPlayer: function(polygon) {

            if (Array.isArray(polygon)) {
                return polygon;
            } else {
                if(typeof polygon === "string") {
                    polygon = JSON.parse(polygon);
                    // console.log("polygon", polygon)

                    var polygon = polygon.map(function(item) {
                        return [item.lat, item.lng];
                    });
                    // console.log("polygon", polygon)

                    let responseArray = [];
                    responseArray.push(polygon);

                    return responseArray;
                }
            }
            return false;
        },
        loadFromPolygon: function(polygon)
        {
            return [inputArray.map(function(coordObject) {
                // Return an array for each object with lat as the first element and lng as the second
                return [coordObject.lat, coordObject.lng];
            })];
        },
        /**
         * Turn lat/lon array into object form
         * @param {*} inputArray
         */
        transformPolygon: function(inputArray) {
            // Input: [[[47.7199614334,8.6526155764],[47.7200894208,8.6528128424],[47.71988932884111,8.652948567794319],[47.7198292965,8.6528604441],[47.7199614334,8.6526155764]]]
            // Output: [{"lat":47.7199614334,"lng":8.6526155764},{"lat":47.7200894208,"lng":8.6528128424},{"lat":47.7199497786,"lng":8.6530692672},{"lat":47.7198292965,"lng":8.6528604441},{"lat":47.7199614334,"lng":8.6526155764}]

            return inputArray[0].map(function(coords) {
                // Return an object for each sub-array with lat and lng properties
                return {
                    lat: coords[0],
                    lng: coords[1]
                };
            });
        },
        showMap: function() {
            // console.log("showMap", this.mapPolygon)
            this.mapVisible = true; // Loads Map Component
            this.mapLoaded = true;
        },
        resetMap: function() {
            // console.log("resetMap");
            this.mapVisible = false;
            this.mapLoaded = false;
        },
        // Energiebezugsfläche berechnen, wenn Gebäude beschnitten wurde. Das kann die Buildings API nicht.
        calcEnergiebezugsflaeche: function(groundArea, buildingLevels, buildingRoofShape) {

            // console.log("calcEnergiebezugsflaeche", groundArea)

            buildingLevels = buildingLevels + 1; // Stockwerke plus Grundgeschoss.

            if(groundArea == 0) {
                return;
            }

            // Flachdach
            if(buildingRoofShape == 1) {

                this.energiebezugsflaeche = groundArea * buildingLevels;

            // Schgrägdach
            } else {

                // Oberstes Geschoss nur halb zählen.
                this.energiebezugsflaeche = (groundArea * buildingLevels) - (groundArea * 0.5);

            }

            this.energiebezugsflaeche = parseInt(this.energiebezugsflaeche);

            // console.log("this.energiebezugsflaeche", this.energiebezugsflaeche)

        }
    }
}
</script>

