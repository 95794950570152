import { reactive } from 'vue'

export const store = reactive({
    csrfToken: $('meta[name="csrf-token"]').attr('content'),
    nextButtonDisabled: 1,
    formData: {},
    validateData: false, // If true the form will be validated (Betriebsenergie)
    completed: 0,
    // Rating
    betriebsenergie_rating: 0,
    bauoekologie_rating: 0,
    standort_rating: 0,
    zersiedlung_rating: 0,
    nutzung_rating: 0,
    wirtschaftlichkeit_rating: 0,
    innovation_rating: 0,
    player: null,
})

