<template>
    <div id="nutzung">
        <div class="intro-text">{{ __('project.nutzung_erklaerung') }}</div>
        <div class="block">

            <h3>{{ __('project.flexibilitaet_konstruktion') }}</h3>

            <!-- Trägerstruktur -->
            <div class="form-group">

                <label class="input-label">{{ __('project.traegerstruktur') }}</label>

                <div class="custom-control custom-radio custom-control-inline">
                    <input  type="radio" id="customRadioInline3" name="customRadioInline1"
                            class="custom-control-input" v-model="formData.traegerstruktur" value="3"
                            @change="changeRating()">
                    <label class="custom-control-label" for="customRadioInline3">{{ __('project.traegerstruktur3') }}</label>
                </div>

                <div class="custom-control custom-radio custom-control-inline">
                    <input  type="radio" id="customRadioInline2" name="customRadioInline1"
                            class="custom-control-input" v-model="formData.traegerstruktur" value="2"
                            @change="changeRating()">
                    <label class="custom-control-label" for="customRadioInline2">{{ __('project.traegerstruktur2') }}</label>
                </div>

                <div class="custom-control custom-radio custom-control-inline">
                    <input  type="radio" id="customRadioInline1" name="customRadioInline1"
                            class="custom-control-input" v-model="formData.traegerstruktur" value="1"
                            @change="changeRating()">
                    <label class="custom-control-label" for="customRadioInline1">{{ __('project.traegerstruktur1') }}</label>
                </div>

            </div>

            <!-- Technische Installationen -->
            <div class="form-group">

                <label class="input-label">{{ __('project.technische_installationen') }}</label>

                <div class="custom-control custom-radio custom-control-inline">
                    <input  type="radio" id="customRadioInline6" name="customRadioInline2"
                            class="custom-control-input" v-model="formData.technische_installationen" value="3"
                            @change="changeRating()">
                    <label class="custom-control-label" for="customRadioInline6">{{ __('project.technische_installationen3') }}</label>
                </div>

                <div class="custom-control custom-radio custom-control-inline">
                    <input  type="radio" id="customRadioInline5" name="customRadioInline2"
                            class="custom-control-input" v-model="formData.technische_installationen" value="2"
                            @change="changeRating()">
                    <label class="custom-control-label" for="customRadioInline5">{{ __('project.technische_installationen2') }}</label>
                </div>

                <div class="custom-control custom-radio custom-control-inline">
                    <input  type="radio" id="customRadioInline4" name="customRadioInline2"
                            class="custom-control-input" v-model="formData.technische_installationen" value="1"
                            @change="changeRating()">
                    <label class="custom-control-label" for="customRadioInline4">{{ __('project.technische_installationen1') }}</label>
                </div>

            </div>
        </div>


        <div class="block">

            <h3>{{ __('project.flexibilitaet_einrichtung') }}</h3>

            <!-- Einrichtungsoptionen -->
            <div class="form-group">

                <label class="input-label">{{ __('project.einrichtungsoptionen') }}</label>

                <div class="custom-control custom-radio custom-control-inline">
                    <input  type="radio" id="customRadioInline8" name="customRadioInline3"
                            class="custom-control-input" v-model="formData.einrichtungsoptionen" value="2"
                            @change="changeRating()">
                    <label class="custom-control-label" for="customRadioInline8">{{ __('project.einrichtungsoptionen2') }}</label>
                </div>

                <div class="custom-control custom-radio custom-control-inline">
                    <input  type="radio" id="customRadioInline7" name="customRadioInline3"
                            class="custom-control-input" v-model="formData.einrichtungsoptionen" value="1"
                            @change="changeRating()">
                    <label class="custom-control-label" for="customRadioInline7">{{ __('project.einrichtungsoptionen1') }}</label>
                </div>

            </div>

            <div class="form-group">

                <label class="input-label">{{ __('project.stauraeume') }}</label>

                <div class="custom-control custom-radio custom-control-inline">
                    <input  type="radio" id="customRadioInline10" name="customRadioInline4"
                            class="custom-control-input" v-model="formData.stauraeume" value="2"
                            @change="changeRating()">
                    <label class="custom-control-label" for="customRadioInline10">{{ __('project.stauraeume2') }}</label>
                </div>

                <div class="custom-control custom-radio custom-control-inline">
                    <input  type="radio" id="customRadioInline9" name="customRadioInline4"
                    class="custom-control-input" v-model="formData.stauraeume" value="1"
                    @change="changeRating()">
                    <label class="custom-control-label" for="customRadioInline9">{{ __('project.stauraeume1') }}</label>
                </div>

            </div>
        </div>

        <div class="points-box mb-2">
            <p class="title mb-2">{{ __('project.nutzung_resultat') }}</p>
            <p class="points mb-0">{{ formData.nutzung_rating }} {{ __('project.von') }} 10</p>
        </div>

    </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        project_data: {
            type: String
        }
    },
    data() {
        return {
            formData: {
                traegerstruktur: "",
                technische_installationen: "",
                einrichtungsoptionen: "",
                stauraeume: "",
                nutzung_rating: 0,
            }
        }
    },
    mounted() {
        var projectData = JSON.parse(this.project_data);

        this.formData.traegerstruktur = projectData.traegerstruktur ?? "";
        this.formData.technische_installationen = projectData.technische_installationen ?? "";
        this.formData.einrichtungsoptionen = projectData.einrichtungsoptionen ?? "";
        this.formData.stauraeume = projectData.stauraeume ?? "";
        this.formData.nutzung_rating = projectData.nutzung_rating ?? 0;

        document.addEventListener('DOMContentLoaded', (event) => {
            this.changeRating();
        });
    },
    methods: {
        changeRating: function() {
            this.formData.nutzung_rating = 0;
            Object.keys(this.formData).forEach(item => {
                if (item == "nutzung_rating") return;
                if (item == "traegerstruktur" || item == "technische_installationen") {
                    if (this.formData[item] == 1) {
                        this.formData.nutzung_rating += 2.5;
                    }
                    if (this.formData[item] == 2) {
                        this.formData.nutzung_rating += 1.5;
                    }
                }
                if ((item == "einrichtungsoptionen" || item == "stauraeume") && this.formData[item] == 1) {
                    this.formData.nutzung_rating += 2.5;
                }
            });
            store.nutzung_rating = this.formData.nutzung_rating;
            store.formData = this.formData;

            this.validateNextButton();
        },
        validateNextButton: function() {
            // If all criteria are met, enable next button
            if(this.formData.traegerstruktur !== '' && this.formData.technische_installationen !== '' && this.formData.einrichtungsoptionen !== '' && this.formData.stauraeume !== '') {
                store.nextButtonDisabled = false;
            } else {
                store.nextButtonDisabled = true;
            }
        }
    }
}
</script>
