<template>
    <div id="nachhaltigkeit">
        <div class="main-box mt-5 mb-3" :class="[nachhaltigkeit === null ? 'disabled' : '']">
            <div style="margin-top: 9px;">{{ __('project.nachhaltigkeits_stufe') }}</div>
            <!-- <div>{{ nachhaltigkeit }}</div> -->
            <div>
                <!-- <img :class="{opacity025: anzahlBirds < 1}" src="/images/abs_bird_transparent.png" width="20" style="margin-right: 7px;">
                <img :class="{opacity025: anzahlBirds < 2}" src="/images/abs_bird_transparent.png" width="20" style="margin-right: 7px;">
                <img :class="{opacity025: anzahlBirds < 3}" src="/images/abs_bird_transparent.png" width="20" style="margin-right: 7px;"> -->
                <img :class="{opacity025: anzahlBirds < 1}" src="/images/bird_ranking_b.svg" width="28" style="margin-right: 7px;">
                <img :class="{opacity025: anzahlBirds < 2}" src="/images/bird_ranking_b.svg" width="28" style="margin-right: 7px;">
                <img :class="{opacity025: anzahlBirds < 3}" src="/images/bird_ranking_b.svg" width="28" style="margin-right: 7px;">
            </div>
        </div>

    </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        project_data: {
            type: String
        }
    },
    data() {
        return {
            nachhaltigkeit: null,
            anzahlBirds: 0
        }
    },
    computed: {
        formData () {
            return store.formData;
        }
    },
    watch: {
        formData: {
            handler: function() {
                console.log("watch formData executed");
                this.setNachhaltigkeitsstufe();
            },
            deep: true
        }
    },
    mounted() {
    },
    methods: {
        setNachhaltigkeitsstufe: function () {

            let zersiedlung_rating = store.zersiedlung_rating;
            if(store.zersiedlung_rating_definitiv > 0) { zersiedlung_rating = store.zersiedlung_rating_definitiv; }

            var total = parseFloat(store.betriebsenergie_rating)
                    + parseFloat(store.bauoekologie_rating)
                    + parseFloat(store.standort_rating)
                    + parseFloat(zersiedlung_rating)
                    + parseFloat(store.nutzung_rating)
                    + parseFloat(store.wirtschaftlichkeit_rating)
                    + parseFloat(store.innovation_rating);




            total = total.toFixed(2);

            if (total < 15) {
                this.nachhaltigkeit = 0;
            }
            else if (total >= 15 && total < 30) {
                this.nachhaltigkeit = 1;
            }
            else if (total >= 30 && total < 45) {
                this.nachhaltigkeit = 2;
            }
            else if (total >= 45 && total <= 60) {
                this.nachhaltigkeit = 3;
            } else if (total > 60) {
                this.nachhaltigkeit = 3; // Sollte nicht eintreffen
            }

            // Mindestanforderung: 2 Punkte bei Betriebsenergie und 2 bei Bauökologie. Sonst Ratig = 0
            if(parseFloat(store.betriebsenergie_rating) < 2 || parseFloat(store.bauoekologie_rating) < 2 ) {
                this.nachhaltigkeit = 0;
            }

            store.formData.nachhaltigkeit = this.nachhaltigkeit;
            this.anzahlBirds = Math.round(this.nachhaltigkeit);
        }
    }
}
</script>
