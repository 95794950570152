<template>
    <Radar :data="chartData" :options="chartOptions" id="radarChart"/>
    <div class="legend mt-4 mb-5">
        <div class="d-flex align-items-center">
            <div class="label dashed"></div>
            <div class="text">{{ __('project.theoretisch_max') }}</div>
        </div>
        <div class="d-flex align-items-center mt-3">
            <div class="label"></div>
            <div class="text">{{ __('project.erreichte_punktzahl') }}</div>
        </div>
    </div>
</template>

<script>

    import { store } from '../store.js'

    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        LineElement,
        PointElement,
        RadialLinearScale
    } from 'chart.js'

    import { Radar } from 'vue-chartjs'

    ChartJS.register(RadialLinearScale, LineElement, PointElement, Title, Tooltip, Legend)

    export default {
        name: 'App',
        components: {
            Radar
        },
        props: {
            project_data: {
                type: String
            }
        },
        data() {
            return {
                once: false,
                bauoekologie_rating: this.__('project.bauoekologie'),
            }
        },
        computed: {
            chartData() {
                return {
                    labels: [
                        this.__('project.betriebsenergie'),
                        this.bauoekologie_rating,
                        this.__('project.standort'),
                        this.__('project.nutzung'),
                        this.__('project.wirtschaftlichkeit'),
                        this.__('project.innovation')
                    ],
                    datasets: [{
                        label: this.__('project.theoretisch_max'),
                        data: [10,10,10,10,10,10],
                        fill: true,
                        pointHitRadius: 2,
                        borderDash: ([7,7]),
                        borderColor: 'rgba(0, 0, 0, 0.7)',
                        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                        pointStyle: false,
                    }, {
                        label: this.__('project.erreichte_punktzahl'),
                        data: [
                            store.betriebsenergie_rating,
                            store.bauoekologie_rating,
                            store.standort_rating + store.zersiedlung_rating,
                            store.nutzung_rating,
                            store.wirtschaftlichkeit_rating,
                            store.innovation_rating
                        ],
                        fill: true,
                        pointHitRadius: 2,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgb(54, 162, 235)',
                        pointBackgroundColor: 'rgb(54, 162, 235)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(54, 162, 235)'
                    }]
                }
            },
            chartOptions() {
                var componentThis = this;
                return {
                    layout: {
                        padding: {
                            left: 0
                        }
                    },
                    responsive: true,
                    elements: {
                        line: {
                            borderWidth: 3,
                            borderLength: 10
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            position: 'bottom',
                            align: 'start',
                            labels: {
                                boxHeight: 0,
                            }

                        }
                    },
                    scales: {
                        r: {
                            suggestedMax: 10,
                            suggestedMin: 0,
                            ticks: {
                                display: false,
                                count: 5,
                                stepSize: 2.5,
                                z: 10,
                                font: {
                                    size: 12,
                                }
                            },
                            pointLabels: {
                                font: {
                                    size: 12
                                }
                            }
                        }
                    },
                    animation: {
                        duration: 0,
                        onComplete: function() {
                            if (this.once == false || this.once == undefined) {
                                if (store.completed == 1 || window.location.pathname.split("/").pop() == "zertifikat") {
                                    $('#content-right').removeClass('col-md-4').addClass('col-md-6');

                                    if (window.innerWidth >= 1400) {
                                        this.options.scales.r.ticks.font.size = 22;
                                        this.options.scales.r.pointLabels.font.size = 21;
                                    }
                                    if (window.innerWidth < 1400) {
                                        this.options.scales.r.ticks.font.size = 19;
                                        this.options.scales.r.pointLabels.font.size = 18;
                                    }
                                    if (window.innerWidth < 1200) {
                                        this.options.scales.r.ticks.font.size = 16;
                                        this.options.scales.r.pointLabels.font.size = 15;
                                    }
                                    if (window.innerWidth < 992) {
                                        this.options.scales.r.ticks.font.size = 15;
                                        this.options.scales.r.pointLabels.font.size = 14;
                                    }
                                    if (window.innerWidth < 830) {
                                        this.options.scales.r.ticks.font.size = 13;
                                        this.options.scales.r.pointLabels.font.size = 13;
                                    }
                                    if (window.innerWidth < 700) {
                                        this.options.scales.r.ticks.font.size = 19;
                                        this.options.scales.r.pointLabels.font.size = 18;
                                    }
                                    if (window.innerWidth < 498) {
                                        this.options.scales.r.ticks.font.size = 15;
                                        this.options.scales.r.pointLabels.font.size = 15;
                                    }
                                    if (window.innerWidth < 438) {
                                        this.options.scales.r.ticks.font.size = 13;
                                        this.options.scales.r.pointLabels.font.size = 13;
                                    }
                                    if (window.innerWidth < 380) {
                                        this.options.scales.r.ticks.font.size = 10;
                                        this.options.scales.r.pointLabels.font.size = 10;
                                    }

                                    console.log("window.innerWidth", window.innerWidth);
                                    if(window._locale == 'fr') {
                                        this.options.layout.padding.left = 20;
                                    }

                                    var _this = this;

                                    store.formData.radarImage = this.toBase64Image();

                                    setTimeout(function(){

                                        // Update Image on Server if URL contains "zertifikat"
                                        if (window.location.href.includes("zertifikat")) {
                                            componentThis.saveChart();
                                        }

                                        setTimeout(function(){
                                            $('#content-right').removeClass('col-md-6').addClass('col-md-4');
                                            $('#content-right').css({visibility: 'visible'});

                                            _this.options.scales.r.ticks.font.size = 12;
                                            _this.options.scales.r.ticks.display = true; // Show Zahlen 0 - 10 nach Bild speichern
                                            _this.options.scales.r.pointLabels.font.size = 12;

                                            _this.update();
                                        }, 200);

                                        _this.once = true;
                                    }, 200);
                                }
                            }
                        }
                    }
                }
            },
            projectDataJson() {
                if(!this.project_data) {
                    return false;
                } else {
                    return JSON.parse(this.project_data);
                }
            },
        },
        mounted() {

            // Langes Label 'Bauökologie' FR auf zwei Zeilen darstellen (array)
            if(window._locale == 'fr') {
                this.bauoekologie_rating = [];
                this.bauoekologie_rating.push(this.__('project.bauoekologie_abkuerzung_1'));
                this.bauoekologie_rating.push(this.__('project.bauoekologie_abkuerzung_2'));
            }

            if (store.completed == 1 || window.location.pathname.split("/").pop() == "zertifikat") {
                $('#content-right').css({visibility: 'hidden'});
            }
        },
        methods: {
            // On Last Page update Image on Server
            saveChart: function() {

                // Just Save store Data
                console.log("Last Page, saveChart() - just store store.formData again");

                axios.post(siteUrl+"/project", {
                    data: store.formData
                })
                .then(response => {
                    console.log(response.data);
                    store.csrfToken = response.data.token;
                });
            }
        }
    }
</script>
