<template>
    <div id="benutzerverwaltung">
        <div class="d-flex justify-content-between">
            <h2 class="page-start mt-0">{{ __('project.benutzerverwaltung') }}</h2>
            <a class="btn btn-secondary" id="newUser" style="height: min-content; width: fit-content;" data-bs-toggle="modal" data-bs-target="#newUserModal">{{ __('project.neuer_benutzer') }}</a>
        </div>
        <div class="user mt-4 pb-3">
            <div class="form-group" v-if="validationErrorsNew.length > 0 || validationErrorsModify.length > 0">
                <input  type="hidden" class="is-invalid">
                <span class="invalid-feedback" role="alert">
                    <strong>{{ __('project.fehlerhafte_angaben') }}</strong>
                </span>
            </div>
            <div class="title_row row pt-4">
                <div class="col-2 ps-0">{{ __('project.name') }}</div>
                <div class="col-4">{{ __('project.email') }}</div>
                <div class="col-2 text-center">{{ __('project.anzahl_projekte') }}</div>
                <div class="col-2">{{ __('project.letzte_anmeldung') }}</div>
                <div class="col-1">{{ __('project.admin') }}</div>
                <div class="col-1"></div>
                <hr class="col-12 mt-3 mb-1">
            </div>

            <div class="pageable">
                <div v-for="(user, index) in usersData" v-bind:value="user.id" v-bind:key="index" class="row pb-1 pt-1">
                    <div class="col-2 ps-0">{{ user.name_copy }}</div>
                    <div class="col-4">{{ user.email }}</div>
                    <div class="col-2 text-center">{{ user.anzahl_projekte }}</div>
                    <div class="col-2">{{ user.last_login }}</div>
                    <div class="col-1">{{ user.userlevel > 1 ? __('project.ja') : __('project.nein') }}</div>
                    <div class="col-1 btns">
                        <a class="btn pen-btn" data-bs-toggle="modal" @click="prepareUpdate(user, index)" data-bs-target="#modifyUserModal">
                            <img src="/images/pen.svg" width="20" style="cursor:pointer"> &nbsp;
                        </a>
                        <a class="btn delete-btn" data-bs-toggle="modal" @click="prepareDelete(user.id,user.name)" data-bs-target="#deleteUserModal">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                                <g><path d="M653.1,867.5c16.8,0,30.6-13.8,30.6-30.6V469.4c0-16.8-13.8-30.6-30.6-30.6c-16.8,0-30.6,13.8-30.6,30.6v367.5C622.5,853.7,636.3,867.5,653.1,867.5z M346.9,867.5c16.8,0,30.6-13.8,30.6-30.6V469.4c0-16.8-13.8-30.6-30.6-30.6s-30.6,13.8-30.6,30.6v367.5C316.3,853.7,330,867.5,346.9,867.5z M836.9,132.5H653.1V71.3c0-33.7-27.6-61.3-61.3-61.3H408.1c-33.7,0-61.3,27.6-61.3,61.3v61.3H163.1c-33.7,0-61.3,27.6-61.3,61.3V255c0,33.7,27.6,61.3,61.3,61.3v551.3c0,67.4,55.1,122.5,122.5,122.5h428.8c67.4,0,122.5-55.1,122.5-122.5V316.3c33.7,0,61.3-27.6,61.3-61.3v-61.2C898.1,160.1,870.6,132.5,836.9,132.5z M408.1,101.9c0-16.8,13.8-30.6,30.6-30.6h122.5c16.8,0,30.6,13.8,30.6,30.6v30.6c-29.9,0-183.8,0-183.8,0V101.9z M775.6,867.5c0,33.7-27.6,61.3-61.3,61.3H285.6c-33.7,0-61.3-27.6-61.3-61.3V316.3h551.3V867.5z M806.3,255H193.8c-16.8,0-30.6-13.8-30.6-30.6s13.8-30.6,30.6-30.6h612.5c16.8,0,30.6,13.8,30.6,30.6S823.1,255,806.3,255z M500,867.5c16.8,0,30.6-13.8,30.6-30.6V469.4c0-16.8-13.8-30.6-30.6-30.6s-30.6,13.8-30.6,30.6v367.5C469.4,853.7,483.2,867.5,500,867.5z"/></g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>

            <div class="modal fade deleteModal" id="deleteUserModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header align-items-center">
                            <div class="modal-title">{{ __('project.benutzer_loeschen') }}</div>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div>
                                    {{ __('project.benutzer_loeschen_text') }}
                                    <br><br>
                                    {{ deleteName }}
                                </div>
                                <div class="buttons">
                                    <div>
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ __('project.abbrechen') }}</button>
                                    </div>

                                    <div>
                                        <button type="button" v-on:click="deleteUser()" class="btn btn-secondary" data-bs-dismiss="modal">{{ __('project.loeschen') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade userModal new" id="newUserModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header align-items-center">
                            <div class="modal-title">{{ __('project.benutzer_anlegen') }}</div>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div class="input-box mb-4">
                                    <div class="form-group mt-2">
                                        <label class="mb-2" for="name">{{ __('project.name') }}</label>
                                        <input  type="text" class="form-control"
                                                :class="[validationKeysNew.indexOf('name') !== -1 ? 'is-invalid' : '']"
                                                v-model="newUser.name">
                                        <span v-if="validationKeysNew.indexOf('name') !== -1" class="invalid-feedback" role="alert">
                                            <strong>{{ validationErrorsNew[validationKeysNew.indexOf('name')] }}</strong>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <label class="mb-2" for="email">{{ __('project.email') }}</label>
                                        <input  type="text" class="form-control"
                                                :class="[validationKeysNew.indexOf('email') !== -1 ? 'is-invalid' : '']"
                                                v-model="newUser.email">
                                        <span v-if="validationKeysNew.indexOf('email') !== -1" class="invalid-feedback" role="alert">
                                            <strong>{{ validationErrorsNew[validationKeysNew.indexOf('email')] }}</strong>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <label class="mb-2" for="password">{{ __('project.passwort') }}</label>
                                        <input  type="password" class="form-control"
                                                :class="[validationKeysNew.indexOf('password') !== -1 ? 'is-invalid' : '']"
                                                v-model="newUser.password">
                                        <span v-if="validationKeysNew.indexOf('password') !== -1" class="invalid-feedback" role="alert">
                                            <strong>{{ validationErrorsNew[validationKeysNew.indexOf('password')] }}</strong>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <label class="mb-2" for="password_confirmation"><span>{{ __('project.passwort_bestaetigen') }} </span></label>
                                        <input  type="password" class="form-control"
                                                :class="[validationKeysNew.indexOf('password') !== -1 ? 'is-invalid' : '']"
                                                v-model="newUser.password_confirmation">
                                    </div>
                                    <div class="form-group">
                                        <label class="">{{ __('project.admin') }}</label>
                                        <input type="checkbox" class="form-check-input mt-0 ms-2" v-model="newUser.admin">
                                    </div>
                                </div>

                                <button type="button" v-on:click="hinzufuegen" class="btn btn-secondary my-3" style="width:fit-content; float:right;" data-bs-dismiss="modal">{{ __('project.benutzer_hinzufuegen') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade userModal" id="modifyUserModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header align-items-center">
                            <div class="modal-title">{{ __('project.benutzer_bearbeiten') }}</div>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div class="input-box mb-4">
                                    <div class="form-group mt-2">
                                        <label class="mb-2" for="name">{{ __('project.name') }}</label>
                                        <input  type="text" class="form-control"
                                                :class="[validationKeysModify.indexOf('name') !== -1 ? 'is-invalid' : '']"
                                                v-model="modifyUser.name">
                                        <span v-if="validationKeysModify.indexOf('name') !== -1" class="invalid-feedback" role="alert">
                                            <strong>{{ validationErrorsModify[validationKeysModify.indexOf('name')] }}</strong>
                                        </span>
                                    </div>

                                    <div class="form-group">
                                        <label class="mb-2" for="email">{{ __('project.email') }}</label>
                                        <input  type="text" class="form-control" disabled style="color: #888899;"
                                                :class="[validationKeysModify.indexOf('email') !== -1 ? 'is-invalid' : '']"
                                                v-model="modifyUser.email">
                                        <span v-if="validationKeysModify.indexOf('email') !== -1" class="invalid-feedback" role="alert">
                                            <strong>{{ validationErrorsModify[validationKeysModify.indexOf('email')] }}</strong>
                                        </span>
                                    </div>

                                    <div class="form-group">
                                        <a style="cursor:pointer;" class="link" @click="resetPassword">{{ __('project.passwort_zuruecksetzen') }}</a>
                                        <div id="reset-message" class="mt-2" style="color:green;"></div>
                                    </div>

                                    <div class="form-group">
                                        <label class="">{{ __('project.admin') }}</label>
                                        <input type="checkbox" class="form-check-input mt-0 ms-2" v-model="modifyUser.admin">
                                    </div>
                                </div>

                                <button type="button" v-on:click="updateUser" class="btn btn-secondary my-3" style="width:fit-content; float:right;" data-bs-dismiss="modal">{{ __('project.speichern') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        users: {
            type: String
        },
        errorsnew: '',
        errorkeysnew: '',
        errorsmodify: '',
        errorkeysmodify: '',
        oldinputs: '',
    },
    data() {
        return {
            usersData: {
                id: {}
            },
            deleteUserId: '',
            deleteName: '',
            newUser: {
                name: '',
                admin: '',
                email: '',
                password: '',
                password_confirmation: ''
            },
            modifyUser: {
                name: '',
                email: '',
                admin: '',
            },
            modifyUserId: '',
            validationErrorsNew: '',
            validationKeysNew: '',
            validationErrorsModify: '',
            validationKeysModify: '',
            oldInputs: ''
        }
    },
    computed: {
        userDataJson() {
            if(!this.users) {
                return false;
            } else {
                return JSON.parse(this.users);
            }
        }
    },
    mounted() {
        this.usersData = this.userDataJson;
        this.validationErrorsNew = JSON.parse(this.errorsnew);
        this.validationKeysNew = JSON.parse(this.errorkeysnew);
        this.validationErrorsModify = JSON.parse(this.errorsmodify);
        this.validationKeysModify = JSON.parse(this.errorkeysmodify);
        this.oldInputs = JSON.parse(this.oldinputs);

        if (jQuery.isEmptyObject(this.oldInputs) === false) {
            console.log(this.oldInputs);
            this.newUser.name = ("name" in this.oldInputs) ? this.oldInputs.name : "";
            this.newUser.admin = ("userlevel" in this.oldInputs) ? this.oldInputs.userlevel > 1 ? true : false : "";
            this.newUser.email = ("email" in this.oldInputs) ? this.oldInputs.email : "";
            this.newUser.password = ("password" in this.oldInputs) ? this.oldInputs.password : "";
            this.newUser.password_confirmation = ("password_confirmation" in this.oldInputs) ? this.oldInputs.password_confirmation : "";
        }

        if (this.validationErrorsNew.length > 0) $('#newUserModal').modal('show');
        if (this.validationErrorsModify.length > 0) $('#modifyUserModal').modal('show');
    },
    methods: {
        hinzufuegen: function() {

            const data = {
                newUser: {
                    name: this.newUser.name,
                    userlevel: this.newUser.admin === true ? 2 : 1,
                    email: this.newUser.email,
                    password: this.newUser.password,
                    password_confirmation: this.newUser.password_confirmation
                }
            };

            axios.post(siteUrl+"/newUser", data)
                .then(response => {
                    console.log("Response:", response.data);
                    window.location.reload();
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("Axios hinzufuegen /newUser Error", error);
                });

        },
        prepareDelete: function(id, name) {
            this.deleteUserId = id;
            this.deleteName = name;
        },
        deleteUser: function() {
            axios.post(siteUrl+"/deleteUser", {
                data: {
                    id: this.deleteUserId,
                    deleted: 1,
                }
            })
            .then(response => {
                console.log("user gelöscht");
                console.log(response.data);
                window.location.reload();
            });
        },
        prepareUpdate: function(user) {
            this.modifyUser = user;
            this.modifyUserId = user.id;
            this.modifyUser.admin = user.userlevel > 1 ? true : false;
        },
        updateUser: function() {
            axios.post(siteUrl+"/updateUser", {
                data: {
                    id: this.modifyUserId,
                    name: this.modifyUser.name,
                    userlevel: this.modifyUser.admin === true ? 2 : 1,
                }
            })
            .then(response => {
                console.log("user updated");
                console.log(response.data);
                window.location.reload();
            });
        },
        resetPassword: function() {
            axios.post(siteUrl+"/customPasswordReset", {
                data: {
                    email: this.modifyUser.email
                }
            })
            .then(response => {
                console.log("user updated");
                console.log(response.data);
                if (response.data.status != null) $('#reset-message').text(response.data.status).css('color', 'green');
                if (response.data.email != null) $('#reset-message').text(response.data.email).css('color', 'red');
            });
        }
    }
}
</script>
