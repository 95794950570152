<template>
        <div class="col-1 delete-angefangenes">
            <svg @click="loeschen(projektId)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g><path d="M653.1,867.5c16.8,0,30.6-13.8,30.6-30.6V469.4c0-16.8-13.8-30.6-30.6-30.6c-16.8,0-30.6,13.8-30.6,30.6v367.5C622.5,853.7,636.3,867.5,653.1,867.5z M346.9,867.5c16.8,0,30.6-13.8,30.6-30.6V469.4c0-16.8-13.8-30.6-30.6-30.6s-30.6,13.8-30.6,30.6v367.5C316.3,853.7,330,867.5,346.9,867.5z M836.9,132.5H653.1V71.3c0-33.7-27.6-61.3-61.3-61.3H408.1c-33.7,0-61.3,27.6-61.3,61.3v61.3H163.1c-33.7,0-61.3,27.6-61.3,61.3V255c0,33.7,27.6,61.3,61.3,61.3v551.3c0,67.4,55.1,122.5,122.5,122.5h428.8c67.4,0,122.5-55.1,122.5-122.5V316.3c33.7,0,61.3-27.6,61.3-61.3v-61.2C898.1,160.1,870.6,132.5,836.9,132.5z M408.1,101.9c0-16.8,13.8-30.6,30.6-30.6h122.5c16.8,0,30.6,13.8,30.6,30.6v30.6c-29.9,0-183.8,0-183.8,0V101.9z M775.6,867.5c0,33.7-27.6,61.3-61.3,61.3H285.6c-33.7,0-61.3-27.6-61.3-61.3V316.3h551.3V867.5z M806.3,255H193.8c-16.8,0-30.6-13.8-30.6-30.6s13.8-30.6,30.6-30.6h612.5c16.8,0,30.6,13.8,30.6,30.6S823.1,255,806.3,255z M500,867.5c16.8,0,30.6-13.8,30.6-30.6V469.4c0-16.8-13.8-30.6-30.6-30.6s-30.6,13.8-30.6,30.6v367.5C469.4,853.7,483.2,867.5,500,867.5z"/></g>
            </svg>
        </div>
        <div class="col-2">
            <a :href="`/loadProject/${projektId}`" class="btn btn-secondary open">Öffnen</a>
        </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        id: {
            type: String
        }
    },
    data() {
        return {
            projektId: ''
        }
    },
    computed: {
    },
    mounted() {
        this.projektId = this.id;

    },
    methods: {
        loeschen: function(id) {

            // var answer = window.confirm("Projekt löschen?");
            // if(!answer) {
            //     return;
            // }

            axios.post(siteUrl+"/deleteUnfinished", {
                id: id
            })
            .then(response => {
                console.log(response.data);
                console.log(response.data.status);

                if(response.data.status === true) {
                    $("#projekt-"+id).hide();
                }

            });
        }
    }
}
</script>
