<template>
    <div id="tabs-nav">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a  class="nav-link"
                    :href="routes.betriebsenergie" 
                    @click="saveProject(routes.betriebsenergie)"
                    :class="[currentPage === 'betriebsenergie' ? 'active' : '']"
                >
                    <span class="d-md-none">1</span>
                    <span class="d-none d-md-block">
                        {{ __("project.betriebsenergie") }}
                    </span>
                </a>
            </li>
            <li class="nav-item">
                <a  class="nav-link"
                    :href="routes.bauoekologie" 
                    @click="saveProject(routes.bauoekologie)"
                    :class="[currentPage === 'bauoekologie' ? 'active' : '', isDisabled(1, 'adresse') ? 'disabled' : '']"
                >
                    <span class="d-md-none">2</span>
                    <span class="d-none d-md-block">
                        {{ __("project.bauoekologie") }}
                    </span>
                </a>
            </li>
            <li class="nav-item">
                <a  class="nav-link"
                    :href="routes.standort" 
                    @click="saveProject(routes.standort)"
                    :class="[currentPage === 'standort' ? 'active' : '', isDisabled(2, 'isolation') ? 'disabled' : '']"
                >
                    <span class="d-md-none">3</span>
                    <span class="d-none d-md-block">
                        {{ __("project.standort") }}
                    </span>                    
                </a>
            </li>
            <li class="nav-item">
                <a  class="nav-link"
                    :href="routes.nutzung" 
                    @click="saveProject(routes.nutzung)"
                    :class="[currentPage === 'nutzung' ? 'active' : '', isDisabled(3, 'streuung') ? 'disabled' : '']"
                >
                    <span class="d-md-none">4</span>
                    <span class="d-none d-md-block">
                        {{ __("project.nutzung") }}
                    </span>   
                </a>
            </li>
            <li class="nav-item">
                <a  class="nav-link"
                    :href="routes.wirtschaftlichkeit" 
                    @click="saveProject(routes.wirtschaftlichkeit)"
                    :class="[currentPage === 'wirtschaftlichkeit' ? 'active' : '', isDisabled(4, 'stauraeume') ? 'disabled' : '']"
                >
                    <span class="d-md-none">5</span>
                    <span class="d-none d-md-block">
                        {{ __("project.wirtschaftlichkeit") }}
                    </span>   
                </a>
            </li>
            <li class="nav-item">
                <a  class="nav-link"
                    :href="routes.innovation" 
                    @click="saveProject(routes.innovation)"
                    :class="[currentPage === 'innovation' ? 'active' : '', isDisabled(5, 'wirtschaftlichkeit_pv') ? 'disabled' : '']"
                >
                    <span class="d-md-none">6</span>
                    <span class="d-none d-md-block">
                        {{ __("project.innovation") }}
                    </span>   
                </a>
            </li>
            <li class="nav-item">
                <a  class="nav-link"
                    :href="routes.zertifikat" 
                    @click="saveProject(routes.zertifikat)"
                    :class="[currentPage === 'zertifikat' ? 'active' : '', isDisabled(6, 'innovation_1') ? 'disabled' : '']"
                >
                    <span class="d-md-none">7</span>
                    <span class="d-none d-md-block">
                        {{ __("project.zertifikat") }}
                    </span>   
                </a>
            </li>
        </ul>
    </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        currentPage: String,
        project_data: String,
        lang: String
    },
    data() {
        return {
            routes: {
                betriebsenergie: window.siteUrl+"/betriebsenergie?lang="+this.lang,
                bauoekologie: window.siteUrl+"/bauoekologie?lang="+this.lang,
                standort: window.siteUrl+"/standort?lang="+this.lang,
                nutzung: window.siteUrl+"/nutzung?lang="+this.lang,
                wirtschaftlichkeit: window.siteUrl+"/wirtschaftlichkeit?lang="+this.lang,
                innovation: window.siteUrl+"/innovation?lang="+this.lang,
                zertifikat: window.siteUrl+"/zertifikat?lang="+this.lang,
            },
            currentPageCounter: 0,
            store
        }
    },
    computed: {
        projectDataJson() {
            if(!this.project_data) {
                return false;
            } else {
                return JSON.parse(this.project_data);
            }
        },
    },
    mounted() {

        switch(this.currentPage) {
            case "betriebsenergie":
                this.currentPageCounter = 0;
                break;
            case "bauoekologie":
                this.currentPageCounter = 1;
                break;
            case "standort":
                this.currentPageCounter = 2;
                break;
            case "nutzung":
                this.currentPageCounter = 3;
                break;
            case "wirtschaftlichkeit":
                this.currentPageCounter = 4;
                break;
            case "innovation":
                this.currentPageCounter = 5;
                break;
            case "zertifikat":
                this.currentPageCounter = 6;
                break;
        }

        console.log("TabsNavigation Mounted, currentPage", this.currentPage, this.currentPageCounter, JSON.parse(this.project_data));

    },
    methods: {
        saveProject: function(target) {
            axios.post(siteUrl+"/project", {
                data: store.formData
            })
            .then(response => {
                console.log(response.data);
                console.log("target", target);
                store.csrfToken = response.data.token;
                window.location = target;
            });
        },
        /**
         * Returns true if the page is disabled
         * @param counter int, Nummer der zu Prüfenden Seite
         * @param checkField string, Feldname, welches Voraussetzugn ist um die Seite zu aktivieren
         */
        isDisabled: function(counter, checkField) {

            // console.log("TabsNavigation, isDisabled("+counter+", "+checkField+") store.nextButtonDisabled", store.nextButtonDisabled, "currentPageCounter", this.currentPageCounter);
            
            // Prüfen ob es eine vorhergehende Seite ist -> dann enablen
            if(this.currentPageCounter >= counter) {
                return false;
                // Nächste Seite freischalten, wenn nextButton enabled ist
            } else if(this.currentPageCounter == counter-1 && store.nextButtonDisabled == false) {
                return false;
            } else {
                // Zukünftige Seiten (falls der User zurück springt in bereits ausgefüllte Seite)
                // In der DB Prüfen ob es ein Wert für das Feld gibt, daher wurde die Seite schon vom User ausgefüllt, daher freischalten
                if(this.isNotEmpty(this.projectDataJson[checkField])) {
                    return false;
                } else {
                    // Disable
                    return true;
                }
            }

            /**
             * Alte Version Adel
             * Hat nicht alle Cases erfüllt.
             */
            /*
            return (this.projectDataJson.adresse !== '' &&
                    (this.currentPageCounter >= counter ||
                    this.currentPageCounter >= counter - 1 && store.nextButtonDisabled == false ||
                    this.projectDataJson[checkField] !== null));
            */
        },
        // Check if a variable is not an empty string, undefined or not null. 0 is allowed
        isNotEmpty: function(value) {
            if(value === null || value === '' || value === undefined) {
                // Its empty
                return false;
            } else {
                return true;
            }
        },
    }
}
</script>
