<template>
    <div id="kontakt">
        <div class="intro-text mt-2">{{ __('project.kontaktdaten_text') }}</div>
        <div class="d-flex">
            <div class="block" style="width:400px;">
                <div class="form-group">
                    <label class="input-label" for="anrede">
                        {{ __('project.anrede') }}
                        <span class="light smaller">({{ __('project.optional') }})</span>
                    </label>
                    <select class="form-select pflichtfeld" name="anrede" id="formControlSelect1"
                            :class="[validationKeys.indexOf('anrede') !== -1 ? 'is-invalid' : '']"
                            v-model="formData.anrede" @change="validateSendButton()">
                        <option value="0" selected>{{ __('project.bitte_waehlen') }}</option>
                        <option value="1">{{ __('project.frau') }}</option>
                        <option value="2">{{ __('project.herr') }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="input-label" for="vorname">{{ __('project.vorname') }}</label>
                    <input
                        type="text" class="form-control pflichtfeld" name="vorname" id="vorname"
                        :class="[validationKeys.indexOf('vorname') !== -1 ? 'is-invalid' : '', prospektberatungDisablerName ? 'disableProspektberatung' : '']"
                        v-model="formData.vorname" @input="validateSendButton()">
                </div>
                <div class="form-group">
                    <label class="input-label" for="name">{{ __('project.name') }}</label>
                    <input
                        type="text" class="form-control pflichtfeld" name="name" id="name"
                        :class="[validationKeys.indexOf('name') !== -1 ? 'is-invalid' : '', prospektberatungDisablerName ? 'disableProspektberatung' : '']"
                        v-model="formData.name" @input="validateSendButton()">
                </div>
                <div class="form-group">
                    <label class="input-label" for="email">{{ __('project.email') }}</label>
                    <input
                        type="text" class="form-control pflichtfeld" name="email" id="email"
                        :class="[validationKeys.indexOf('email') !== -1 ? 'is-invalid' : '', prospektberatungDisablerEmail ? 'disableProspektberatung' : '']"
                        v-model="formData.email" @input="validateSendButton()">
                    <span v-if="validationKeys.indexOf('email') !== -1" class="invalid-feedback" role="alert">
                        <strong>{{ validationErrors[validationKeys.indexOf('email')] }}</strong>
                    </span>
                </div>
                <div class="form-group mb-0">
                    <label class="input-label" for="telefon pflichtfeld">{{ __('project.telefon') }}</label>
                    <input
                        type="text" class="form-control" name="telefon" id="telefon"
                        :class="[validationKeys.indexOf('telefon') !== -1 ? 'is-invalid' : '', prospektberatungDisablerTelefon ? 'disableProspektberatung' : '']"
                        v-model="formData.telefon" @input="validateSendButton()">
                </div>
                <div class="form-group">
                    <label class="input-label" for="firmenname_architekt">{{ __('project.firmenname_architekt') }} <span class="light smaller">({{ __('project.optional') }})</span></label>
                    <input  type="text" class="form-control" name="firmenname_architekt" id="firmenname_architekt"
                            :class="[validationKeys.indexOf('firmenname_architekt') !== -1 ? 'is-invalid' : '']"
                            v-model="formData.firmenname_architekt" @input="validateSendButton()">
                </div>
                <div class="form-group">
                    <label class="input-label" for="name_architekt">{{ __('project.name_architekt') }} <span class="light smaller">({{ __('project.optional') }})</span></label>
                    <input  type="text" class="form-control" name="name_architekt" id="name_architekt"
                            :class="[validationKeys.indexOf('name_architekt') !== -1 ? 'is-invalid' : '']"
                            v-model="formData.name_architekt" @input="validateSendButton()">
                </div>
                <div class="form-group">
                    <label class="input-label" for="telefon_architekt">{{ __('project.telefon_architekt') }} <span class="light smaller">({{ __('project.optional') }})</span></label>
                    <input  type="text" class="form-control" name="telefon_architekt" id="telefon_architekt"
                            :class="[validationKeys.indexOf('telefon_architekt') !== -1 ? 'is-invalid' : '']"
                            v-model="formData.telefon_architekt" @input="validateSendButton()">
                </div>
                <div class="form-group">
                    <label class="input-label" for="email_architekt">{{ __('project.email_architekt') }} <span class="light smaller">({{ __('project.optional') }})</span></label>
                    <input  type="text" class="form-control" name="email_architekt" id="email_architekt"
                            :class="[validationKeys.indexOf('email_architekt') !== -1 ? 'is-invalid' : '']"
                            v-model="formData.email_architekt" @input="validateSendButton()">
                </div>

                <!-- <div class="form-group">
                    <label class="input-label" for="labels">{{ __('project.labels') }}</label>
                    <select
                        class="form-select"
                        name="labels"
                        id="formControlSelect2"
                        v-model="formData.labels"
                    >
                        <option value="" disabled selected>{{ __('project.bitte_waehlen') }}</option>
                        <option value="Kein Label">{{ __('project.kein_label') }}</option>
                        <option value="Minergie P">{{ __('project.minergie_p') }}</option>
                        <option value="Minergie A">{{ __('project.minergie_a') }}</option>
                        <option value="Minergie Eco">{{ __('project.minergie_eco') }}</option>
                        <option value="GEAK">{{ __('project.geak') }}</option>
                        <option value="SNBS">{{ __('project.snbs') }}</option>
                        <option value="DGNB">{{ __('project.dgnb') }}</option>
                        <option value="HPE - THPE">{{ __('project.hpe_thpe') }}</option>
                    </select>
                </div> -->

                <!-- Checkboxen Labels -->
                <div class="form-group mb-5">
                    <label class="input-label" for="labels">{{ __('project.labels') }}</label>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="box1" :value="__('project.minergie_p')" v-model="formData.labels">
                        <label class="form-check-label" for="box1">
                            {{ __('project.minergie_p') }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="box2" :value="__('project.minergie_a')" v-model="formData.labels">
                        <label class="form-check-label" for="box2">
                            {{ __('project.minergie_a') }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="box3" :value="__('project.minergie_eco')" v-model="formData.labels">
                        <label class="form-check-label" for="box3">
                            {{ __('project.minergie_eco') }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="box4" :value="__('project.geak')" v-model="formData.labels">
                        <label class="form-check-label" for="box4">
                            {{ __('project.geak') }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="box8" :value="__('project.geak_plus')" v-model="formData.labels">
                        <label class="form-check-label" for="box8">
                            {{ __('project.geak_plus') }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="box5" :value="__('project.snbs')" v-model="formData.labels">
                        <label class="form-check-label" for="box5">
                            {{ __('project.snbs') }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="box6" :value="__('project.dgnb')" v-model="formData.labels">
                        <label class="form-check-label" for="box6">
                            {{ __('project.dgnb') }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="box7" :value="__('project.hpe_thpe')" v-model="formData.labels">
                        <label class="form-check-label" for="box7">
                            {{ __('project.hpe') }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="box7" :value="__('project.hpe_thpe')" v-model="formData.labels">
                        <label class="form-check-label" for="box7">
                            {{ __('project.thpe') }}
                        </label>
                    </div>
                </div>

            </div>
        </div>

        <div class="form-group mb-0 mt-0">
            <p><b>{{ __('project.kontaktaufnahme') }}</b></p>
            <div class="form-check">
                <!-- Möchte kontaktiert werden -->
                <input class="form-check-input" type="checkbox" id="blankCheckbox1" v-model="formData.kontaktwunsch">
                <label class="form-check-label" for="blankCheckbox1">{{ __('project.zertifikat2') }}</label>
            </div>
        </div>
        <div class="mt-5 mb-5">
            <a
                role="button" id="absendenButton"
                :class="[sendButtonDisabled ? 'disabled' : '']" class="btn btn-secondary btn-lg" v-on:click="sendContact($event)"
                style="margin-left: 246px; position: relative;">
                {{ __('project.absenden') }}
                <div
                        v-if="showLoader"
                        class="spinner-border" role="status"
                        style="position: absolute; top: 7px; left: 4px; opacity: 0.7;"
                ></div>
            </a>
        </div>
    </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        project_data: {
            type: String
        },
        kontakt_data: {
            type: String
        },
        user: {
            type: String
        },
        errors: '',
        errorkeys: '',
        oldinputs: '',
    },
    data() {
        return {
            formData: {
                project_id: "",
                anrede: "",
                vorname: "",
                name: "",
                email: "",
                telefon: "",
                name_architekt: "",
                firmenname_architekt: "",
                telefon_architekt: "",
                email_architekt: "",
                labels: [],
                kontaktwunsch: false, // Möchte kontaktiert werden
                dontSentMail: false
            },
            labels: [],
            sendButtonDisabled: true,
            validationErrors: '',
            validationKeys: '',
            oldInputs: '',
            showLoader: false,
            prospektberatungDisablerName: false,
            prospektberatungDisablerEmail: false,
            prospektberatungDisablerTelefon: false
        }
    },
    computed: {
        projectDataJson() {
            if(!this.project_data) {
                return false;
            } else {
                return JSON.parse(this.project_data);
            }
        },
    },
    mounted() {
        this.formData.project_id = this.projectDataJson.id;

        var kontaktData = JSON.parse(this.kontakt_data);
        console.log("mounted kontaktData", kontaktData)
        this.validationErrors = JSON.parse(this.errors);
        this.validationKeys = JSON.parse(this.errorkeys);
        this.oldInputs = JSON.parse(this.oldinputs);

        // console.log("validationErrors, validationKeys", this.validationErrors, this.validationKeys);

        if (kontaktData !== null) {
            this.formData = kontaktData;
            this.formData.kontaktwunsch = this.projectDataJson.kontaktwunsch == 1 ? true : false;
            this.formData.labels = this.formData.labels.split(", ");
        }

        if(this.formData.labels == null) {
            this.formData.labels = [];
        }

        console.log("this.formData", this.formData);

        // Wenn Berater Eingeloggt ist
        if(window.berater == true) {
            if(this.formData.vorname == '' && this.formData.name == '') {
                this.formData.vorname = 'Prospektberatung';
                this.formData.name = 'Prospektberatung';
                this.prospektberatungDisablerName = true;
            } else {
                this.prospektberatungDisablerName = false;
            }
            if(this.formData.email == '') {
                this.formData.email = 'prospektberatung@abs.ch';
                this.prospektberatungDisablerEmail = true;
            } else {
                this.prospektberatungDisablerEmail = false;
            }
            if(this.formData.telefon == '') {
                this.formData.telefon = '000 000 00 00';
                this.prospektberatungDisablerTelefon = true;
            } else {
                this.prospektberatungDisablerTelefon = false;
            }
        }

        if (jQuery.isEmptyObject(this.oldInputs.data) === false) {
            this.formData = this.oldInputs.data;
        }

        this.validateSendButton();
    },
    methods: {
        sendContact: function(event) {

            console.log("sendContact this.formData", this.formData);

            event.preventDefault();

            // this.showFormValidation();

            if(event.target.id == 'absendenButton' && this.sendButtonDisabled) {
                return;
            }

            // Show Loader
            this.showLoader = true;

            if(this.formData.dontSentMail == true) {
                alert(window._resend_form);
            }

            // Labels zu einem kommaseparierten String zusammenfügen
            this.formData.labels = this.formData.labels.join(", ");

            axios.post(siteUrl+"/kontakt", {
                data: this.formData
            })
            .then(response => {
                console.log(response.data.status);
                this.showLoader = false;
                store.csrfToken = response.data.token;
                if (response.data.status == true) {
                    window.location = "/abschluss";
                } else {
                    window.location.reload();
                }
            });
        },
        /**
         * Add red border to not filled fields (if visible)
         */
        // showFormValidation: function() {
        //     console.log("showFormValidation");

        //     $(".pflichtfeld").removeClass('form-error');

        //     // Loop over Elements with class .pflichtfeld
        //     // and add class .error if not visible and not empty
        //     var thisComponent = this;
        //     var firstFieldWithError = false;
        //     $('.pflichtfeld').each(function(i, obj) {

        //         console.log(this.id, "is visible?", $(this).is(":visible"), "value:", $(this).val());

        //         if(
        //             thisComponent.isNotEmpty($(this).val()) == false
        //             && $(this).is(":visible")
        //         ) {
        //             console.log(this.id, "add form-error");
        //             $(this).addClass('form-error');
        //             if(firstFieldWithError == false) {
        //                 firstFieldWithError = this.id;
        //             }
        //         }

        //     });

        //     if(firstFieldWithError) {
        //         document.getElementById(firstFieldWithError).scrollIntoView();
        //     }

        // },
        // Check if a variable is not an empty string, undefined or not null. 0 is allowed
        isNotEmpty: function(value) {
            if(value === null || value === '' || value === undefined) {
                // Its empty
                return false;
            } else {
                return true;
            }
        },
        validateSendButton: function() {
            // console.log("validateSendButton", this.formData);

            // If all criteria are met, enable next button
            if(
                this.formData.vorname !== '' &&
                this.formData.name !== '' &&
                this.formData.email !== '' &&
                this.formData.telefon !== ''
                // this.formData.anrede !== '' &&
                // this.formData.name_architekt !== '' && // Optional
                // this.formData.telefon_architekt !== '' // Optional
                )
            {
                this.sendButtonDisabled = false;

                // Wenn bereits versendet wurde disablen
                if(this.projectDataJson.kontaktwunsch !== null) {
                    console.log("Mail bereits versendet, disabled")
                    // if((this.user !== 'null') {} // Eingeloggte user sollen nicht senden können
                    // this.sendButtonDisabled = true;

                    if(window.berater == true) {
                        this.formData.dontSentMail = true; // Mail already sent. Form wurde bereits einmal ausgefüllt.
                    }
                }
            } else {
                this.sendButtonDisabled = true;
            }
        }
    }
}
</script>
