<template>
    <div class="mt-5 clearfix">
        <a
            v-if="previous != ''"
            role="button"
            id="backButton"
            :href="previous"
            class="btn btn-secondary btn-lg"
            v-on:click.prevent="saveProject($event)"
        >
            {{ __('project.zurueck') }}
        </a>

        <button
            v-if="next != ''"
            role="button"
            id="weiterButton"
            class="btn btn-secondary btn-lg"
            :class="[store.nextButtonDisabled ? 'disabled' : '']"
            v-on:click.prevent="saveProject($event)"
            style="position:absolute; right:0;"
        >
            {{ __('project.weiter') }}
        </button>
    </div>
</template>

<script>

// If Store nextButtonDisabled changes, enable/disable NextButton

import { store } from '../store.js'

export default {
    props: {
        previous: String,
        next: String
    },
    data() {
        return {
            store
        }
    },
    mounted() {
        store.nextButtonDisabled = true;
    },
    methods: {
        saveProject: function(event) {

            console.log("NextButton saveProject()", event.target.id);

            store.validateData = true; // Trigger validation (Betriebsenergie)

            if(event.target.id == 'weiterButton' && store.nextButtonDisabled) {
                return;
            }

            axios.post(siteUrl+"/project", {
                data: store.formData
            })
            .then(response => {
                console.log(response.data);
                store.csrfToken = response.data.token;

                if(event.target.id == 'weiterButton') {
                    window.location = this.next;
                } else if(event.target.id == 'backButton') {
                    window.location = this.previous;
                }
            });
        }
    }
}
</script>
