<template>
    <div id="projektverwaltung">
        <div class="projekte mt-4 pb-3">
            <div class="title_row row pt-2">
                <div class="col-1 ps-0">
                    <div class="clickable clicked" v-on:click="sortBy($event, 'id')">
                        {{ __('project.datum') }}
                        <img :src="'/images/arrow_right.svg'" class="order_arrow">
                    </div>
                </div>
                <div class="col-1 ps-0">
                    <div class="clickable" v-on:click="sortBy($event, 'kontaktwunsch')">
                        {{ __('project.beratung') }}
                        <img :src="'/images/arrow_right.svg'" class="order_arrow">
                    </div>
                </div>
                <div class="col-2 ps-0 customer">
                    <div class="clickable" v-on:click="sortBy($event, 'kunde')">
                        {{ __('project.kunde') }}
                        <img :src="'/images/arrow_right.svg'" class="order_arrow">
                    </div>
                </div>
                <div class="col-2 address">
                    <div class="clickable" v-on:click="sortBy($event, 'adresse')">
                        {{ __('project.liegenschaft') }}
                        <img :src="'/images/arrow_right.svg'" class="order_arrow">
                    </div>
                </div>
                <div class="col-2">
                    <div class="clickable" v-on:click="sortBy($event, 'status')">
                        {{ __('project.status') }}
                        <img :src="'/images/arrow_right.svg'" class="order_arrow">
                    </div>
                </div>
                <div class="col-2">
                    <div class="clickable" v-on:click="sortBy($event, 'user_id')">
                        {{ __('project.berater') }}
                        <img :src="'/images/arrow_right.svg'" class="order_arrow">
                    </div>
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
                <div class="col-1 ps-0 mt-3">
                    <div class="form-check mt-1">
                        <input class="form-check-input" type="checkbox" id="filterBeratung" checked @change="getCurrentProjects(1)">
                    </div>
                </div>
                <div class="col-2 ps-0 mt-3 customer">
                    <div id="searchCustomer" data-init="formtextinput">
                        <input type="text" class="form-control" style="background-image: url('/images/search.png');" :placeholder="__('project.suchen')" @keyup="search()">
                    </div>
                </div>
                <div class="col-2 mt-3 address">
                    <div id="searchAddress" data-init="formtextinput">
                        <input type="text" class="form-control" style="background-image: url('/images/search.png');" :placeholder="__('project.suchen')" @keyup="search()">
                    </div>
                </div>
                <div class="col-2 mt-3">
                    <div>
                        <select v-on:change="getCurrentProjects(1), filterStateChanged(value)" id="filterState" class="w-100 form-select">
                            <option value="5">{{ __('project.alle') }}</option>
                            <option value="0">{{ __('project.status0') }}</option>
                            <option value="1">{{ __('project.status1') }}</option>
                            <option value="2">{{ __('project.status2') }}</option>
                            <option value="3">{{ __('project.status3') }}</option>
                            <option value="4">{{ __('project.status4') }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-2 mt-3">
                    <div>
                        <select v-on:change="getCurrentProjects(1)" id="filterCoach" class="w-100 form-select">
                            <option value="1000">{{ __('project.alle') }}</option>
                            <option v-for="(user, index) in usersData" v-bind:value="user.id" v-bind:key="index">
                                {{ user.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <hr class="col-12 mt-3 mb-1">
            </div>

            <div class="pageable">
                <div v-for="(project, index) in currentProjects.data" v-bind:key="index" class="row pb-1 pt-1">
                    <div class="col-1 ps-0">{{ project.created_at }}</div>
                    <div class="col-1 ps-0">{{ project.kontaktwunsch == 1 ? __('project.ja') : __('project.nein') }}</div>
                    <div class="col-2 ps-0 customer">
                        <table style="width: 100%;">
                            <tr>
                                <td>
                                    <span>{{ project.k_vorname }} {{ project.k_name }}</span>
                                </td>
                                <td
                                    data-bs-target="#userdataModal"
                                    data-bs-toggle="modal"
                                    @click="prepareUserdataModal(project)"
                                    class="projv-btn"
                                    style="width: 38px; padding-left: 8px;"
                                >
                                    <img src="/images/userdata.png" style="height: 30px; cursor: pointer; opacity: 0.9;" title="Benutzerdaten">
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-2 address">{{ project.adresse }}</div>
                    <div class="col-2">
                        <select v-model="project.status" v-on:change="updateState(project.id, project.status)" class="w-100 form-select">
                            <option value="0">{{ __('project.status0') }}</option>
                            <option value="1">{{ __('project.status1') }}</option>
                            <option value="2">{{ __('project.status2') }}</option>
                            <option value="3">{{ project.status == 3 ? __('project.status3,2') : __('project.status3') }}</option>
                            <option value="4">{{ __('project.status4') }}</option>
                        </select>
                    </div>
                    <div class="col-2">
                        <select v-model="project.user_id" v-on:change="updateBerater(project.id, project.user_id)" class="w-100 form-select">
                            <option v-for="(user, index) in usersData" :value="user.id" :key="index">
                                {{ user.name }}
                            </option>
                        </select>
                    </div>

                    <!-- Icons / Öffnen -->
                    <div class="col-auto btns">
                        <!-- Zersiedlung -->
                        <span
                            data-bs-target="#zersiedlungModal"
                            data-bs-toggle="modal"
                            @click="prepareSetZersiedlungsRating(project.id, project.zersiedlung_rating, project.zersiedlung_rating_definitiv)"
                        >
                            <img src="/images/location.png" style="height: 30px; margin-right: 9px;" :title="__('project.zersiedlungsrating')" class="icon">
                        </span>
                        <!-- Kommentar - Bemerkung -->
                        <span
                            data-bs-target="#notesModal"
                            data-bs-toggle="modal"
                            @click="prepareSetNotesModal(project.id, project.notes)"
                        >
                            <img src="/images/notes.png" style="height: 28px; margin-right: 5px; opacity: 0.6;" :title="__('project.notes')" class="icon">
                        </span>
                        <!-- PDF -->
                        <a :href="'getPdf/'+project.id" target="_blank" class="btn pdf-btn" :title="__('project.pdf_zertifikat')">
                            <img src="/images/pdf-icon.png">
                        </a>
                        <!-- Löschen -->
                        <a
                            class="btn delete-btn"
                            data-bs-toggle="modal"
                            @click="prepareDelete(project.id, project.adresse, project.k_vorname + ' ' +project.k_name)"
                            data-bs-target="#deleteProjectModal"
                            :title="__('project.loeschen')"
                        >
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                                <g><path d="M653.1,867.5c16.8,0,30.6-13.8,30.6-30.6V469.4c0-16.8-13.8-30.6-30.6-30.6c-16.8,0-30.6,13.8-30.6,30.6v367.5C622.5,853.7,636.3,867.5,653.1,867.5z M346.9,867.5c16.8,0,30.6-13.8,30.6-30.6V469.4c0-16.8-13.8-30.6-30.6-30.6s-30.6,13.8-30.6,30.6v367.5C316.3,853.7,330,867.5,346.9,867.5z M836.9,132.5H653.1V71.3c0-33.7-27.6-61.3-61.3-61.3H408.1c-33.7,0-61.3,27.6-61.3,61.3v61.3H163.1c-33.7,0-61.3,27.6-61.3,61.3V255c0,33.7,27.6,61.3,61.3,61.3v551.3c0,67.4,55.1,122.5,122.5,122.5h428.8c67.4,0,122.5-55.1,122.5-122.5V316.3c33.7,0,61.3-27.6,61.3-61.3v-61.2C898.1,160.1,870.6,132.5,836.9,132.5z M408.1,101.9c0-16.8,13.8-30.6,30.6-30.6h122.5c16.8,0,30.6,13.8,30.6,30.6v30.6c-29.9,0-183.8,0-183.8,0V101.9z M775.6,867.5c0,33.7-27.6,61.3-61.3,61.3H285.6c-33.7,0-61.3-27.6-61.3-61.3V316.3h551.3V867.5z M806.3,255H193.8c-16.8,0-30.6-13.8-30.6-30.6s13.8-30.6,30.6-30.6h612.5c16.8,0,30.6,13.8,30.6,30.6S823.1,255,806.3,255z M500,867.5c16.8,0,30.6-13.8,30.6-30.6V469.4c0-16.8-13.8-30.6-30.6-30.6s-30.6,13.8-30.6,30.6v367.5C469.4,853.7,483.2,867.5,500,867.5z"/></g>
                            </svg>
                        </a>
                    </div>

                    <!-- Öffnen -->
                    <div class="col-auto px-0">
                        <a :href="'loadProject/'+project.id" class="btn btn-secondary open" style="padding: 0.45rem 0.5rem;">
                            <!-- {{ __('project.oeffnen') }} -->
                            <img src="/images/open.png" class="icon" style="height: 20px; margin-top: -2px; filter: brightness(0) invert(1);">
                        </a>
                    </div>
                </div>
            </div>

            <div class="searchable" style="display:none;">
                <div v-for="(project, index) in projectsData" v-bind:key="index" class="row pb-1 pt-1">
                    <div class="col-1 ps-0">{{ project.created_at }}</div>
                    <div class="col-1 ps-0">{{ project.kontaktwunsch == 1 ? __('project.ja') : __('project.nein') }}</div>
                    <div class="col-2 ps-0 customer">{{ project.k_vorname }} {{ project.k_name }}</div>
                    <div class="col-2 address">{{ project.adresse }}</div>
                    <div class="col-2">
                        <select v-model="project.status" v-on:change="updateState(project.id, project.status)" class="w-100 form-select">
                            <option value="0">{{ __('project.status0') }}</option>
                            <option value="1">{{ __('project.status1') }}</option>
                            <option value="2">{{ __('project.status2') }}</option>
                            <option value="3">{{ project.status == 3 ? __('project.status3,2') : __('project.status3') }}</option>
                            <option value="4">{{ __('project.status4') }}</option>
                        </select>
                    </div>
                    <div class="col-2">
                        <select v-model="project.user_id" v-on:change="updateBerater(project.id, project.user_id)" class="w-100 form-select">
                            <option v-for="(user, index) in usersData" v-bind:value="user.id" v-bind:key="index">
                                {{ user.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-1 btns">
                        <a class="btn delete-btn" :id="project.id" @click="deleteModal(project.id, $event)">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                                <g><path d="M653.1,867.5c16.8,0,30.6-13.8,30.6-30.6V469.4c0-16.8-13.8-30.6-30.6-30.6c-16.8,0-30.6,13.8-30.6,30.6v367.5C622.5,853.7,636.3,867.5,653.1,867.5z M346.9,867.5c16.8,0,30.6-13.8,30.6-30.6V469.4c0-16.8-13.8-30.6-30.6-30.6s-30.6,13.8-30.6,30.6v367.5C316.3,853.7,330,867.5,346.9,867.5z M836.9,132.5H653.1V71.3c0-33.7-27.6-61.3-61.3-61.3H408.1c-33.7,0-61.3,27.6-61.3,61.3v61.3H163.1c-33.7,0-61.3,27.6-61.3,61.3V255c0,33.7,27.6,61.3,61.3,61.3v551.3c0,67.4,55.1,122.5,122.5,122.5h428.8c67.4,0,122.5-55.1,122.5-122.5V316.3c33.7,0,61.3-27.6,61.3-61.3v-61.2C898.1,160.1,870.6,132.5,836.9,132.5z M408.1,101.9c0-16.8,13.8-30.6,30.6-30.6h122.5c16.8,0,30.6,13.8,30.6,30.6v30.6c-29.9,0-183.8,0-183.8,0V101.9z M775.6,867.5c0,33.7-27.6,61.3-61.3,61.3H285.6c-33.7,0-61.3-27.6-61.3-61.3V316.3h551.3V867.5z M806.3,255H193.8c-16.8,0-30.6-13.8-30.6-30.6s13.8-30.6,30.6-30.6h612.5c16.8,0,30.6,13.8,30.6,30.6S823.1,255,806.3,255z M500,867.5c16.8,0,30.6-13.8,30.6-30.6V469.4c0-16.8-13.8-30.6-30.6-30.6s-30.6,13.8-30.6,30.6v367.5C469.4,853.7,483.2,867.5,500,867.5z"/></g>
                            </svg>
                        </a>
                        <a :href="'getPdf/'+project.id" target="_blank" class="btn pdf-btn">
                            <img src="/images/pdf-icon.png">
                        </a>
                    </div>
                    <div class="col-1 px-0">
                        <a :href="'loadProject/'+project.id" class="btn btn-secondary">
                            {{ __('project.oeffnen') }}
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="currentProjects.total > 0">
                <div class="row mt-2">
                    <div class="col-12 mt-4">
                        <Pagination
                            v-model="page"
                            :records="currentProjects.total"
                            :per-page="currentProjects.per_page"
                            @paginate="getCurrentProjects"
                            :options="{ chunksNavigation:'scroll', hideCount:true,}"
                        />
                    </div>
                </div>
            </div>

            <!-- Modal Delete Project -->
            <div class="modal fade deleteModal" id="deleteProjectModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header align-items-center">
                            <div class="modal-title">{{ __('project.projekt_loeschen') }}</div>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div>
                                    {{ __('project.projekt_loeschen_text') }}
                                    <br><br>
                                    {{ deleteAddress }} {{ __('project.von') }} {{ deleteCustomer }}
                                </div>
                                <div class="buttons">
                                    <div>
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ __('project.abbrechen') }}</button>
                                    </div>

                                    <div>
                                        <button type="button" v-on:click="deleteProject()" class="btn btn-secondary" data-bs-dismiss="modal">{{ __('project.loeschen') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Zersiedlung -->
            <div class="modal fade deleteModal" id="zersiedlungModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header align-items-center">

                            <div class="modal-title">{{ __('project.zersiedlung_eintragen') }}</div>

                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>

                                <div>
                                    {{ __('project.zersiedlung_pruefen') }}
                                </div>
                                <table class="mt-4">
                                    <tr>
                                        <td style="padding-right: 20px; padding-bottom: 10px;">
                                            {{ __('project.profisorisches_ergebnis') }}:
                                        </td>
                                        <td style="padding-bottom: 10px;">
                                            {{ zersiedlungsRatingProvisorisch }} / 5
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding-right: 20px;">
                                            {{ __('project.definitives_ergebnis') }}:
                                        </td>
                                        <td>
                                            <input type="text" id="zersiedlungsratingDefinitiv" v-model="zersiedlungsRatingDefinitiv" style="width: 70px; text-align: center; margin-right: 10px;">
                                            <span>{{ zersiedlungsRatingDefinitivPoints }}</span>
                                        </td>
                                    </tr>
                                </table>

                                <div class="buttons mt-5">
                                    <div>
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ __('project.abbrechen') }}</button>
                                    </div>

                                    <div>
                                        <button type="button" v-on:click="setZersiedlungsRating()" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Notes -->
            <div class="modal fade deleteModal" id="notesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document" style="max-width: 600px;">
                    <div class="modal-content">
                        <div class="modal-header align-items-center">

                            <div class="modal-title">{{ __('project.notes') }}</div>

                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <textarea v-model="notes" placeholder="" style="width: 100%;" rows="12"></textarea>

                            <div class="buttons mt-5">
                                <div>
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ __('project.abbrechen') }}</button>
                                </div>

                                <div>
                                    <button type="button" v-on:click="setNotes()" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Userdata -->
            <div class="modal fade deleteModal" id="userdataModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header align-items-center">

                            <div class="modal-title">{{ __('project.benutzerdaten') }}</div>

                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>

                                <table class="table" id="userdata">
                                    <tr>
                                        <td>{{ __('project.anrede') }}</td>
                                        <td v-if="this.userData.anrede == 1">{{ __('project.frau') }}</td>
                                        <td v-else="this.userData.anrede == 2">{{ __('project.herr') }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ __('project.vorname') }}</td>
                                        <td>{{ this.userData.vorname }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ __('project.name') }}</td>
                                        <td>{{ this.userData.name }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ __('project.email') }}</td>
                                        <td>{{ this.userData.email }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ __('project.telefon') }}</td>
                                        <td>{{ this.userData.telefon }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ __('project.name_architekt') }}</td>
                                        <td>{{ this.userData.architekt }} </td>
                                    </tr>
                                    <tr>
                                        <td>{{ __('project.firmenname_architekt') }}</td>
                                        <td>{{ this.userData.architekt_firma }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ __('project.telefon_architekt') }}</td>
                                        <td>{{ this.userData.architekt_telefon }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ __('project.email_architekt') }}</td>
                                        <td>{{ this.userData.architekt_email }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ __('project.labels') }}</td>
                                        <td>{{ this.userData.labels }}</td>
                                    </tr>
                                </table>

                                <div class="buttons mt-5">
                                    <div>
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ __('project.abbrechen') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>

import { store } from '../store.js'
import Pagination from 'v-pagination-3';

export default {
    props: {
        projects: {
            type: String
        },
        users: {
            type: String
        }
    },
    components: {
        Pagination,
    },
    data() {
        return {
            usersData: {},
            projectsData: {},
            currentProjects: {},
            sortedBy: 'id',
            order: 'desc',
            page: 1,
            deleteProjectId: '',
            deleteAddress: '',
            deleteCustomer: '',
            zersiedlungsRatingProjectId: '',
            zersiedlungsRatingProvisorisch: '',
            zersiedlungsRatingDefinitiv: '',
            notesProjectId: '',
            notes: '',
            userData: {}
        }
    },
    computed: {
        projectsJson() {
            if(!this.projects) {
                return false;
            } else {
                return JSON.parse(this.projects);
            }
        },
        zersiedlungsRatingDefinitivPoints() {
            if (this.zersiedlungsRatingDefinitiv >= 0.8) {
                return 5;
            }
            else if (this.zersiedlungsRatingDefinitiv > 0.5 && this.zersiedlungsRatingDefinitiv < 0.8) {
                return 3;
            }
            else {
                return 0;
            }
        }
    },
    mounted() {
        this.projectsData = this.projectsJson;
        this.usersData = JSON.parse(this.users);
        console.log("usersData", this.usersData);
        console.log("projectsData", this.projectsData);

        this.getCurrentProjects();
    },
    methods: {
        async getCurrentProjects(page=1) {
            var filterState = $('#filterState').val();
            var filterCoach = $('#filterCoach').val();
            var filterBeratung = $('#filterBeratung').is(':checked');

            await axios.get("/paginate?page="+page+"&filterBeratung="+filterBeratung+"&filterState="+filterState+"&filterCoach="+filterCoach+"&orderBy="+this.sortedBy+"&order="+this.order)
            .then( response => {
                this.currentProjects = response.data.paginated;
                this.projectsData = response.data.projects;
                console.log("paginated this.currentProjects", this.currentProjects);

                // if already something searched, search in the returned projects again
                if ($('#searchAddress input').val().length >= 3 || $('#searchCustomer input').val().length >= 3) {
                    var _this = this;
                    setTimeout(function() {
                        _this.search();
                    }, 10);
                }
            }).catch(({ response })=>{
                console.error(response);
            })
        },
        search: function() {
            var customerFilter = $('#searchCustomer input').val().toUpperCase(); // case insensitiv
            var addressFilter = $('#searchAddress input').val().toUpperCase(); // case insensitiv

            if (customerFilter.length < 3 && addressFilter.length < 3) {
                $('.pagination').show();
                $('.pageable').show();
                $('.searchable').hide();
            }
            else {
                $('.pagination').hide();
                $('.pageable').hide();
                $('.searchable').show();
                $('.searchable').find('.row').hide().filter(function () {
                    var foundCustomer = false;
                    var foundAddress = false;
                    var foundBoth = false;

                    // If only customer searched
                    if (customerFilter.length >= 3 && addressFilter.length < 3) {
                        $(this).find('.customer').each(function () {
                            if ($(this).text().toUpperCase().indexOf(customerFilter) > -1) // case insensitiv
                            {
                                foundCustomer = true;
                                return false;
                            }
                        });
                        return foundCustomer;
                    }
                    // If only address searched
                    else if (customerFilter.length < 3 && addressFilter.length >= 3) {
                        $(this).find('.address').each(function () {
                            if ($(this).text().toUpperCase().indexOf(addressFilter) > -1) // case insensitiv
                            {
                                foundAddress = true;
                                return false;
                            }
                        });
                        return foundAddress;
                    }
                    // If both searched
                    else {
                        $(this).find('.address').each(function () {
                            if ($(this).text().toUpperCase().indexOf(addressFilter) > -1) // case insensitiv
                            {
                                foundAddress = true;
                            }
                        });
                        $(this).find('.customer').each(function () {
                            if ($(this).text().toUpperCase().indexOf(customerFilter) > -1) // case insensitiv
                            {
                                foundCustomer = true;
                            }
                        });
                        if (foundAddress && foundCustomer) {
                            foundBoth = true;
                        }
                        return foundBoth;
                    }
                }).show();
            }
        },
        sortBy: function(event, key) {

            this.sortedBy = key;

            $('.clicked').removeClass('clicked');

            if ($(event.target).parent().hasClass("clickable") && $(event.target).parent().hasClass("ascending")) {
                $(event.target).parent().removeClass("ascending");
                $(event.target).parent().addClass("clicked");
                this.order = "desc";
            }
            else if ($(event.target).parent().hasClass("clickable")) {
                $(event.target).parent().addClass("ascending");
                $(event.target).parent().addClass("clicked");
                this.order = "asc";
            }

            if ($(event.target).hasClass("clickable") && $(event.target).hasClass("ascending")) {
                $(event.target).removeClass("ascending");
                $(event.target).addClass("clicked");
                this.order = "desc";
            }
            else if ($(event.target).hasClass("clickable")) {
                $(event.target).addClass("ascending");
                $(event.target).addClass("clicked");
                this.order = "asc";
            }

            this.getCurrentProjects();
        },
        filterStateChanged: function() {
            if ($('#filterState').val() == 3) {
                $('#filterState option:eq(4)').text(this.__('project.status3,2'));
            } else{
                $('#filterState option:eq(4)').text(this.__('project.status3'));
            };
        },
        prepareDelete: function(id, address, customer) {
            this.deleteProjectId = id;
            this.deleteAddress = address;
            this.deleteCustomer = customer;
        },
        deleteProject: function() {
            axios.post("/deleteProject/"+this.deleteProjectId)
                .then(response => {
                    console.log("projekt gelöscht");
                    location.reload();
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("Axios post /delete", error);
                });
        },
        prepareSetZersiedlungsRating: function(projectId, ratingProvisorisch, zersiedlungsRatingDefinitiv) {
            console.log("prepareSetZersiedlungsRating", projectId, ratingProvisorisch)
            this.zersiedlungsRatingProjectId = projectId;
            this.zersiedlungsRatingProvisorisch = ratingProvisorisch;
            if(zersiedlungsRatingDefinitiv == 0) { zersiedlungsRatingDefinitiv = ''; }
            this.zersiedlungsRatingDefinitiv = zersiedlungsRatingDefinitiv;
        },
        prepareSetNotesModal: function(projectId, notes) {
            console.log("prepareSetNotesModal", projectId, notes)
            this.notesProjectId = projectId;
            this.notes = notes;
        },
        prepareUserdataModal: function(projectData) {
            console.log("prepareUserdataModal", projectData)

            this.userData = {
                anrede: projectData.k_anrede,
                vorname: projectData.k_vorname,
                name: projectData.k_name,
                email: projectData.k_email,
                telefon: projectData.k_telefon,
                architekt: projectData.k_architekt,
                architekt_firma: projectData.k_architekt_firma,
                architekt_telefon: projectData.k_architekt_telefon,
                architekt_email: projectData.k_architekt_email,
                labels: projectData.k_labels
            }
        },
        setZersiedlungsRating: function() {
            console.log("setZersiedlungsRating", this.zersiedlungsRatingProjectId, this.zersiedlungsRatingDefinitiv)
            axios.post("/zersiedlungsrating/"+this.zersiedlungsRatingProjectId, {
                rating: this.zersiedlungsRatingDefinitiv
            })
            .then(response => {
                console.log("Zersiedlungs Rating gesetzt", response);
                location.reload();
            })
            .catch(error => {
                console.error("Axios post /setZersiedlungsRating", error);
            });
        },
        setNotes: function() {
            console.log("setNotes", this.notes)
            axios.post("/notes/"+this.notesProjectId, {
                notes: this.notes
            })
            .then(response => {
                console.log("Notes Rating gesetzt", response);
                location.reload();
            })
            .catch(error => {
                console.error("Axios post /setNotes", error);
            });
        },
        updateState: function(project_id, project_status) {

            axios.post(siteUrl+"/projektverwaltungUpdate", {
                data: {
                    id: project_id,
                    status: project_status
                }
            })
            .then(response => {
                console.log(response.data);
            });
        },
        updateBerater: function(project_id, berater) {

            console.log(berater);

            axios.post(siteUrl+"/projektverwaltungUpdate", {
                data: {
                    id: project_id,
                    user_id: berater
                }
            })
            .then(response => {
                console.log(response.data);
            });
        }
    }
}
</script>
