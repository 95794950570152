<template>
    <div id="rating">
        <h2>
            <div style="position:relative; width:max-content;">
                {{ __('project.ergebnis') }}
                <!--

                    <div v-if="tooltip" class="custom-tooltip" data-bs-custom-class="tooltipOpacity" style="bottom:7px; right:-30px;" data-toggle="tooltip" data-html="true" title='<div class="tooltip_value">tbd</div>'>
                        <img src="/images/info.png" width="22">
                    </div>
                -->
            </div>
        </h2>
        <div class="kategorie">{{ __('project.umwelt') }}</div>
        <div class="item">
            <img v-if="currentPage === 'betriebsenergie'" src="/images/right-arrow.png" width="20">
            <p :class="[currentPage === 'betriebsenergie' ? 'active' : '']">
               {{ __('project.betriebsenergie') }}:
               <span class="rating-label" :class="betriebsenergieLabel">{{ this.betriebsenergieRating }} / 10</span>
               <!-- <div class="indikator" style="display: inline-block; width: 13px; height: 13px; background-color: red; border-radius: 50%;"></div> -->
            </p>
        </div>
        <div class="item">
            <img v-if="currentPage === 'bauoekologie'" src="/images/right-arrow.png" width="20">
            <p :class="[currentPage === 'bauoekologie' ? 'active' : '', isDisabled(1, 'adresse') ? '' : 'disabled']">
                {{ __('project.bauoekologie') }}:
                <span class="rating-label" :class="bauoekologieLabel">{{ this.bauoekologieRating }} / 10</span>
            </p>
        </div>
        <div class="kategorie" :class="[isDisabled(2, 'isolation') ? '' : 'disabled']">{{ __('project.gesellschaft') }}</div>
        <div class="item">
            <img v-if="currentPage === 'standort' && store.formData.streuung === '' && store.formData.gebaeudetyp === ''" src="/images/right-arrow.png" width="20">
            <p :class="[currentPage === 'standort' ? 'active' : '', isDisabled(2, 'isolation') ? '' : 'disabled']">
                {{ __('project.standort') }} & {{ __('project.zersiedlung') }}:
                <span class="rating-label" :class="standortLabel">{{ this.standortRating }} / 10</span>
            </p>
        </div>
        <!-- <div class="item">
            <img v-if="currentPage === 'standort' && (store.formData.streuung !== '' || store.formData.gebaeudetyp !== '')" src="/images/right-arrow.png" width="20">
            <p :class="[currentPage === 'standort' ? 'active' : '', isDisabled(2, 'isolation') ? '' : 'disabled']">
                {{ __('project.zersiedlung') }}: {{ this.zersiedlungRating }} / 5
            </p>
        </div> -->
        <div class="item">
            <img v-if="currentPage === 'nutzung'" src="/images/right-arrow.png" width="20">
            <p :class="[currentPage === 'nutzung' ? 'active' : '', isDisabled(3, 'streuung') ? '' : 'disabled']">
                {{ __('project.nutzung') }}:
                <span class="rating-label" :class="nutzungLabel">{{ this.nutzungRating }} / 10</span>
            </p>
        </div>
        <div class="kategorie" :class="[isDisabled(4, 'stauraeume') ? '' : 'disabled']">{{ __('project.wirtschaftlichkeit') }}</div>
        <div class="item">
            <img v-if="currentPage === 'wirtschaftlichkeit'" src="/images/right-arrow.png" width="20">
            <p :class="[currentPage === 'wirtschaftlichkeit' ? 'active' : '', isDisabled(4, 'stauraeume') ? '' : 'disabled']">
                {{ __('project.wirtschaftlichkeit') }}:
                <span class="rating-label" :class="wirtschaftlichkeitLabel">{{ this.wirtschaftlichkeitRating }} / 10</span>
            </p>
        </div>
        <div class="kategorie" :class="[isDisabled(5, 'wirtschaftlichkeit_pv') ? '' : 'disabled']">{{ __('project.innovation') }}</div>
        <div class="item">
            <img v-if="currentPage === 'innovation'" src="/images/right-arrow.png" width="20">
            <p :class="[currentPage === 'innovation' ? 'active' : '', isDisabled(5, 'wirtschaftlichkeit_pv') ? '' : 'disabled']">
                {{ __('project.zusatzkriterien') }}:
                <span class="rating-label" :class="innovationLabel">{{ this.innovationRating }} / 10</span>
            </p>
        </div>
    </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        project_data: {
            type: String
        },
        currentPage: {
            type: String
        },
        tooltip: {
            type: String
        }
    },
    data() {
        return {
            currentPageCounter: 0,
            store,
            bauoekologieLabel: 'red',
            betriebsenergieLabel: '',
            standortLabel: '',
            nutzungLabel: '',
            wirtschaftlichkeitLabel: '',
            innovationLabel: ''
        }
    },
    computed: {
        projectDataJson() {
            if(!this.project_data) {
                return false;
            } else {
                return JSON.parse(this.project_data);
            }
        },
        betriebsenergieRating() {

            this.betriebsenergieLabel = '';
            if(this.store.betriebsenergie_rating > 6) {
                this.betriebsenergieLabel = 'green';
            } else if(this.store.betriebsenergie_rating > 3) {
                this.betriebsenergieLabel = 'yellow';
            } else if(this.store.betriebsenergie_rating > 0) {
                this.betriebsenergieLabel = 'red';
            }

            // Round to one decimal
            return Math.round((this.store.betriebsenergie_rating + Number.EPSILON) * 10) / 10;
        },
        bauoekologieRating() {

            this.bauoekologieLabel = '';
            if(this.store.bauoekologie_rating > 6) {
                this.bauoekologieLabel = 'green';
            } else if(this.store.bauoekologie_rating > 3) {
                this.bauoekologieLabel = 'yellow';
            } else if(this.store.bauoekologie_rating > 0) {
                this.bauoekologieLabel = 'red';
            }

            // Round to one decimal
            return Math.round((this.store.bauoekologie_rating + Number.EPSILON) * 10) / 10;
        },
        // Standort und Zersiedlung soll ein Rating sein
        standortRating() {

            let zersiedlung_rating = this.store.zersiedlung_rating;
            if(store.zersiedlung_rating_definitiv > 0) { zersiedlung_rating = this.store.zersiedlung_rating_definitiv; }

            let ratingTotal = this.store.standort_rating + zersiedlung_rating;
            this.standortLabel = '';
            if(ratingTotal > 6) {
                this.standortLabel = 'green';
            } else if(ratingTotal > 3) {
                this.standortLabel = 'yellow';
            } else if(ratingTotal > 0) {
                this.standortLabel = 'red';
            }

            // Round to one decimal
            return Math.round((this.store.standort_rating + zersiedlung_rating + Number.EPSILON) * 10) / 10;
        },
        // zersiedlungRating() {
            //     // Round to one decimal
            //     return Math.round((this.store.zersiedlung_rating + Number.EPSILON) * 10) / 10;
            // },
            nutzungRating() {

            this.nutzungLabel = '';
            if(this.store.nutzung_rating > 6) {
                this.nutzungLabel = 'green';
            } else if(this.store.nutzung_rating > 3) {
                this.nutzungLabel = 'yellow';
            } else if(this.store.nutzung_rating > 0) {
                this.nutzungLabel = 'red';
            }

            // Round to one decimal
            return Math.round((this.store.nutzung_rating + Number.EPSILON) * 10) / 10;
        },
        wirtschaftlichkeitRating() {

            this.wirtschaftlichkeitLabel = '';
            if(this.store.wirtschaftlichkeit_rating > 6) {
                this.wirtschaftlichkeitLabel = 'green';
            } else if(this.store.wirtschaftlichkeit_rating > 3) {
                this.wirtschaftlichkeitLabel = 'yellow';
            } else if(this.store.wirtschaftlichkeit_rating > 0) {
                this.wirtschaftlichkeitLabel = 'red';
            }

            // Round to one decimal
            return Math.round((this.store.wirtschaftlichkeit_rating + Number.EPSILON) * 10) / 10;
        },
        innovationRating() {

            this.innovationLabel = '';
            if(this.store.innovation_rating > 6) {
                this.innovationLabel = 'green';
            } else if(this.store.innovation_rating > 3) {
                this.innovationLabel = 'yellow';
            } else if(this.store.innovation_rating > 0) {
                this.innovationLabel = 'red';
            }

            // Round to one decimal
            return Math.round((this.store.innovation_rating + Number.EPSILON) * 10) / 10;
        }
    },
    mounted() {
        switch(this.currentPage) {
            case "betriebsenergie":
                this.currentPageCounter = 0;
                break;
            case "bauoekologie":
                this.currentPageCounter = 1;
                break;
            case "standort":
                this.currentPageCounter = 2;
                break;
            case "nutzung":
                this.currentPageCounter = 3;
                break;
            case "wirtschaftlichkeit":
                this.currentPageCounter = 4;
                break;
            case "innovation":
                this.currentPageCounter = 5;
                break;
            case "zertifikat":
                this.currentPageCounter = 6;
                break;
        }
    },
    methods: {
        isDisabled: function(counter, checkField) {
            return (this.projectDataJson.adresse !== '' &&
                    (this.currentPageCounter >= counter ||
                    this.projectDataJson[checkField] !== null));
        }
    }
}
</script>
