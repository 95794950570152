<template>
    <div id="bauoekologie">
        <div class="intro-text">{{ __('project.bauoekologie_erklaerung') }}</div>
        <div class="block">
            <h3>{{ __('project.aussenverkleidung') }}</h3>
            <div class="inner">
                <div class="form-group my-4 d-flex flex-column">

                    <!-- Dachform -->
                    <label class="input-label">{{ __('project.dachform') }}</label>
                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input
                            type="radio"
                            class="btn-check" name="btn-group1" id="btnradio1"
                            v-model="formData.dachform"
                            value="1"
                            @change="changeRating(), updateTooltip()"
                            autocomplete="off"
                        >
                        <label class="btn btn-outline-primary" for="btnradio1">{{ __('project.schraegdach') }}</label>
                        <input
                            type="radio"
                            class="btn-check" name="btn-group1" id="btnradio2"
                            v-model="formData.dachform"
                            value="2"
                            @change="changeRating(), updateTooltip()"
                            autocomplete="off"
                        >
                        <label class="btn btn-outline-primary" for="btnradio2">{{ __('project.flachdach') }}</label>
                    </div>

                </div>
                <div class="form-group" :class="{ deaktiviert: formData.dachform == '' }">
                    <label class="input-label">{{ __('project.baustandard_dach') }}</label>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline3" name="customRadioInline2"
                               class="answer custom-control-input"
                               v-model="formData.baustandard_dach"
                               :disabled="formData.dachform == ''"
                               value="1"
                               @change="changeRating()"
                               data_points="0"
                        >
                        <label class="custom-control-label" for="customRadioInline3">
                            {{ __('project.konventionell') }}
                            <div
                                v-if="formData.dachform == 1"
                                class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                                :title="tooltipDiv+__('project.schraegdach_tooltip1')+tooltipDivEnd"
                            >
                                <img src="/images/info.png" width="17">
                            </div>
                            <div
                                v-if="formData.dachform == 2"
                                class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                                :title="tooltipDiv+__('project.flachdach_tooltip1')+tooltipDivEnd"
                            >
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline4" name="customRadioInline2"
                               class="answer custom-control-input"
                               v-model="formData.baustandard_dach"
                               :disabled="formData.dachform == ''"
                               value="2"
                               @change="changeRating()"
                               data_points="0.7"
                        >
                        <label class="custom-control-label" for="customRadioInline4">
                            {{ __('project.teilweise_umweltfreundlich') }}
                            <div
                                v-if="formData.dachform == 1"
                                class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                                :title="tooltipDiv+__('project.schraegdach_tooltip2')+tooltipDivEnd"
                            >
                                <img src="/images/info.png" width="17">
                            </div>
                            <div
                                v-if="formData.dachform == 2"
                                class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true"
                                :title="tooltipDiv+__('project.flachdach_tooltip2')+tooltipDivEnd"
                            >
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline5" name="customRadioInline2"
                               class="answer custom-control-input"
                               v-model="formData.baustandard_dach"
                               :disabled="formData.dachform == ''"
                               value="3"
                               @change="changeRating()"
                               data_points="1.25"
                        >
                        <label class="custom-control-label" for="customRadioInline5">
                            {{ __('project.total_umweltfreundlich') }}
                            <div v-if="formData.dachform == 1" class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.schraegdach_tooltip3')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                            <div v-if="formData.dachform == 2" class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.flachdach_tooltip3')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                </div>
                <div class="form-group d-flex flex-column">
                    <label class="input-label">
                        {{ __('project.fassadentyp') }}
                        <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.fassadentyp_tooltip')+tooltipDivEnd">
                            <img src="/images/info.png" width="17">
                        </div>
                    </label>
                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input
                            type="radio"
                            class="btn-check"
                            name="btn-group2"
                            id="btnradio3"
                            v-model="formData.fassadentyp"
                            value="1"
                            @change="changeRating(), updateTooltip()"
                            autocomplete="off"
                        >
                        <label class="btn btn-outline-primary" for="btnradio3">{{ __('project.hinterlueftete_fassade') }}</label>

                        <input
                            type="radio"
                            class="btn-check"
                            name="btn-group2"
                            id="btnradio4"
                            v-model="formData.fassadentyp"
                            value="2"
                            @change="changeRating(), updateTooltip()"
                            autocomplete="off"
                        >
                        <label class="btn btn-outline-primary" for="btnradio4">{{ __('project.nicht_belueftet') }}</label>
                    </div>
                </div>
                <div class="form-group" :class="{ deaktiviert: formData.fassadentyp == '' }">
                    <label class="input-label">{{ __('project.baustandard_fassade') }}</label>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline8" name="customRadioInline4"
                               class="answer custom-control-input"
                               v-model="formData.baustandard_fassade"
                               :disabled="formData.fassadentyp == ''"
                               value="1"
                               @change="changeRating()"
                               data_points="0"
                        >
                        <label class="custom-control-label" for="customRadioInline8">
                            {{ __('project.konventionell') }}
                            <div v-if="formData.fassadentyp == 1 || formData.fassadentyp == ''" class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.hinterlueftete_fassade_tooltip1')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                            <div v-if="formData.fassadentyp == 2" class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.nicht_belueftet_tooltip1')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline9" name="customRadioInline4"
                               class="answer custom-control-input"
                               v-model="formData.baustandard_fassade"
                               :disabled="formData.fassadentyp == ''"
                               value="2"
                               @change="changeRating()"
                               data_points="0.7"
                        >
                        <label class="custom-control-label" for="customRadioInline9">
                            {{ __('project.teilweise_umweltfreundlich') }}
                            <div v-if="formData.fassadentyp == 1 || formData.fassadentyp == ''" class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.hinterlueftete_fassade_tooltip2')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                            <div v-if="formData.fassadentyp == 2" class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.nicht_belueftet_tooltip2')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline10" name="customRadioInline4"
                               class="answer custom-control-input"
                               v-model="formData.baustandard_fassade"
                               :disabled="formData.fassadentyp == ''"
                               value="3"
                               @change="changeRating()"
                               data_points="1.25"
                        >
                        <label class="custom-control-label" for="customRadioInline10">
                            {{ __('project.total_umweltfreundlich') }}
                            <div v-if="formData.fassadentyp == 1 || formData.fassadentyp == ''" class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.hinterlueftete_fassade_tooltip3')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                            <div v-if="formData.fassadentyp == 2" class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.nicht_belueftet_tooltip3')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="block">
            <h3>{{ __('project.isolation') }}</h3>
            <div class="inner">
                <div class="form-group">
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadioInline11" name="customRadioInline5"
                            class="answer custom-control-input"
                            v-model="formData.isolation"
                            value="1"
                            @change="changeRating()"
                            data_points="0"
                        >
                        <label class="custom-control-label" for="customRadioInline11">
                            {{ __('project.konventionell_f') }}
                            <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.isolation_tooltip1')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadioInline12" name="customRadioInline5"
                            class="answer custom-control-input"
                            v-model="formData.isolation"
                            value="2"
                            @change="changeRating()"
                            data_points="1.5"
                        >
                        <label class="custom-control-label" for="customRadioInline12">
                            {{ __('project.teilweise_umweltfreundlich') }}
                            <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.isolation_tooltip2')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadioInline13" name="customRadioInline5"
                            class="answer custom-control-input"
                            v-model="formData.isolation"
                            value="3"
                            @change="changeRating()"
                            data_points="2.5"
                        >
                        <label class="custom-control-label" for="customRadioInline13">
                            {{ __('project.total_umweltfreundlich') }}
                            <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.isolation_tooltip3')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="block">
            <h3>{{ __('project.innenverkleidungen') }}</h3>
            <div class="inner">
                <div class="form-group">
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadioInline14" name="customRadioInline6"
                            class="answer custom-control-input"
                            v-model="formData.innenverkleidung"
                            value="1"
                            @change="changeRating()"
                            data_points="0"
                        >
                        <label class="custom-control-label" for="customRadioInline14">
                            {{ __('project.konventionell_p') }}
                            <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innenverkleidungen_tooltip1')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadioInline15" name="customRadioInline6"
                            class="answer custom-control-input"
                            v-model="formData.innenverkleidung"
                            value="2"
                            @change="changeRating()"
                            data_points="1.5"
                        >
                        <label class="custom-control-label" for="customRadioInline15">
                            {{ __('project.teilweise_umweltfreundlich_p') }}
                            <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innenverkleidungen_tooltip2')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadioInline16" name="customRadioInline6"
                            class="answer custom-control-input"
                            v-model="formData.innenverkleidung"
                            value="3"
                            @change="changeRating()"
                            data_points="2.5"
                        >
                        <label class="custom-control-label" for="customRadioInline16">
                            {{ __('project.total_umweltfreundlich_p') }}
                            <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.innenverkleidungen_tooltip3')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="block">
            <h3>{{ __('project.strukturelles_system') }}</h3>
            <div class="inner">
                <div class="form-group">
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadioInline17" name="customRadioInline7"
                            class="answer custom-control-input"
                            v-model="formData.strukturelles_system"
                            value="1"
                            @change="changeRating()"
                            data_points="0"
                        >
                        <label class="custom-control-label" for="customRadioInline17">
                            {{ __('project.konventionell') }}
                            <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.strukturelles_system_tooltip1')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadioInline18" name="customRadioInline7"
                            class="answer custom-control-input"
                            v-model="formData.strukturelles_system"
                            value="2"
                            @change="changeRating()"
                            data_points="1.5"
                        >
                        <label class="custom-control-label" for="customRadioInline18">
                            {{ __('project.teilweise_umweltfreundlich') }}
                            <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+__('project.strukturelles_system_tooltip2')+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadioInline19" name="customRadioInline7"
                            class="answer custom-control-input"
                            v-model="formData.strukturelles_system"
                            value="3"
                            @change="changeRating()"
                            data_points="2.5"
                        >
                        <label class="custom-control-label" for="customRadioInline19">
                            {{ __('project.total_umweltfreundlich') }}
                            <div class="custom-tooltip" data-bs-custom-class="tooltipOpacity" data-toggle="tooltip" data-html="true" :title="tooltipDiv+tooltipDiv+__('project.strukturelles_system_tooltip3')+tooltipDivEnd+tooltipDivEnd">
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="points-box mb-2">
            <p class="title mb-2">{{ __('project.bauoekologie_ergebnis') }}</p>
            <p class="points mb-0">{{ formData.bauoekologie_rating }} {{ __('project.von') }} 10</p>
        </div>
    </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        project_data: {
            type: String
        }
    },
    data() {
        return {
            formData: {
                dachform: "",
                baustandard_dach: "",
                fassadentyp: "",
                baustandard_fassade: "",
                isolation: "",
                innenverkleidung: "",
                strukturelles_system: "",
                bauoekologie_rating: 0,
            },
            tooltipDiv: "<div class='tooltip_value'>",
            tooltipDivEnd: "</div>"
        }
    },
    mounted() {
        var projectData = JSON.parse(this.project_data);

        this.formData.dachform = projectData.dachform ?? "";
        this.formData.baustandard_dach = projectData.baustandard_dach ?? "";
        this.formData.fassadentyp = projectData.fassadentyp ?? "";
        this.formData.baustandard_fassade = projectData.baustandard_fassade ?? "";
        this.formData.isolation = projectData.isolation ?? "";
        this.formData.innenverkleidung = projectData.innenverkleidung ?? "";
        this.formData.strukturelles_system = projectData.strukturelles_system ?? "";
        this.formData.bauoekologie_rating = projectData.bauoekologie_rating ?? 0;


        document.addEventListener('DOMContentLoaded', (event) => {
            this.changeRating();
        });

    },
    methods: {
        updateTooltip: function() {
            $('[data-toggle="tooltip"]').tooltip({html:true});
        },
        changeRating: function() {

            this.formData.bauoekologie_rating = 0;

            // Loop over all Answers (.answer class)
            var answers = document.getElementsByClassName("answer");
            for (var i = 0; i < answers.length; i++) {
                if(answers[i].checked) {

                    // Get data_points Attribute
                    let points = answers[i].getAttribute("data_points");
                    // console.log("points: " + points);

                    // Add points to rating
                    this.formData.bauoekologie_rating += Number(points);
                    // console.log("rating neu: " + this.formData.bauoekologie_rating);
                }
            }


            this.formData.bauoekologie_rating = Math.round((parseFloat(this.formData.bauoekologie_rating) + Number.EPSILON) * 10) / 10; // Rounding
            store.bauoekologie_rating = this.formData.bauoekologie_rating;

            store.formData = this.formData;

            this.validateNextButton();

            // Hmm has bizli apasst, has gfühl biz eifach zum versto und apasse. Chasch eifach Pünkt obe ide Attribut data_points apasse

            /*
            this.formData.bauoekologie_rating = 0;
            Object.keys(this.formData).forEach(item => {

                if (item == "bauoekologie_rating" || item == "dachform" || item == "fassadentyp") return;
                if (this.formData[item] == 2) {
                    this.formData.bauoekologie_rating += item == "baustandard_dach" || item == "baustandard_fassade" ? 0.7 : 1.5;
                }
                if (this.formData[item] == 3) {
                    this.formData.bauoekologie_rating += item == "baustandard_dach" || item == "baustandard_fassade" ? 1.25 : 2.5;
                }
            });
            store.bauoekologie_rating = this.formData.bauoekologie_rating;
            */

        },
        validateNextButton: function() {
            // If all criteria are met, enable next button
            if(this.formData.dachform !== '' && this.formData.baustandard_dach !== '' && this.formData.fassadentyp !== '' && this.formData.baustandard_fassade !== '' && this.formData.isolation !== '' && this.formData.innenverkleidung !== '' && this.formData.strukturelles_system) {
                store.nextButtonDisabled = false;
            } else {
                store.nextButtonDisabled = true;
            }
        }
    }
}
</script>
